import { useEffect, useState } from 'react';

// Components
import CustomDataTable from '../../components/DataTable';
import AddEditEducation from '../../components/AddEditEducation';

// Constants
import { DEBOUNCE_TIMEOUT, EDUCATIONS_COLUMNS, GRADE_CODE } from '../../constants/general';

// Utility Functions
import { deleteEducation, getEducationDetailsData } from '../../middleware/educationDetails';

// Utility Packages
import { useSelector } from 'react-redux';
import { ConfirmDialog } from 'primereact/confirmdialog';
import { Button } from 'primereact/button';
import { dispatch } from '../../utils/store';
import { Actions } from '../../redux/actions';
import { debounce } from '../../utils/debounce';
import moment from 'moment/moment';
import { Tooltip as ReactTooltip } from 'react-tooltip';

// images
import editIcon from '../../assets/images/grid-edit-icon.svg';
import deleteIcon from '../../assets/images/grid-delete-icon.svg';

function EducationDetails() {
  // Store
  const toastReff = useSelector((state) => state.notifications.toastReff);
  const { educations, totalRecords, search, pageNumber, size } = useSelector(
    (state) => state.educationDetails
  );

  // State
  const [educationModal, setEducationModal] = useState(false);
  const [educationsDetails, setEducationsDetails] = useState([]);
  const [selectedEducation, setSelectedEducation] = useState(null);
  const [deleteEducationModal, setDeleteEducationModal] = useState(false);

  useEffect(
    () => getEducationDetailsData(search, pageNumber, size, toastReff),
    [search, pageNumber, size]
  );

  const onEditClick = (education) => {
    setSelectedEducation(education);
    setEducationModal(true);
  };

  const onDeleteClick = (education) => {
    setSelectedEducation(education);
    setDeleteEducationModal(true);
  };

  useEffect(() => {
    return () => {
      dispatch(Actions.Educations.EducationsReset);
    };
  }, []);
  useEffect(() => {
      const modifiedRecords = educations?.map((education) => {
        return {
          ...education,
          PassingYear: `${moment(education.PassingYear).format('YYYY')}`,
          Grade: education.Grade ? GRADE_CODE[education.Grade] : '--',
          action: (
            <div className="table-actions-wrapper">
              <Button
                data-tooltip-id="edit-button"
                data-tooltip-content="Edit"
                // icon="pi pi-pencil"
                rounded
                text
                severity="secondary"
                aria-label="Edit"
                onClick={() => onEditClick(education)}
                className="grid-action-btn">
                <em>
                  <img src={editIcon} alt="edit icon" />
                </em>
              </Button>
              <Button
                data-tooltip-id="delete-button"
                data-tooltip-content="Delete"
                // icon="pi pi-trash"
                rounded
                text
                // severity="danger"
                aria-label="Delete"
                onClick={() => onDeleteClick(education)}
                className="grid-action-btn">
                <em>
                  <img src={deleteIcon} alt="delete icon" />
                </em>
              </Button>
              <ReactTooltip id="edit-button" place="top" />
              <ReactTooltip id="delete-button" place="top" />
            </div>
          )
        };
      });
      setEducationsDetails(modifiedRecords);
  }, [educations]);

  const handlePagination = (pageNumber, size) => {
    dispatch(Actions.Educations.SetPageNumber, pageNumber);
    dispatch(Actions.Educations.SetPageSize, size);
  };

  const handleSearch = (searchValue) => {
    dispatch(Actions.Educations.SetSearch, searchValue);
  };

  return (
    <>
      <ConfirmDialog
        header="Delete"
        message="Are you sure you want to delete?"
        icon="pi pi-exclamation-triangle"
        visible={deleteEducationModal}
        onHide={() => {
          setSelectedEducation(null);
          setDeleteEducationModal(false);
        }}
        accept={() =>
          deleteEducation(
            selectedEducation?.ID,
            search,
            pageNumber,
            size,
            toastReff,
            setDeleteEducationModal
          )
        }
      />
      <CustomDataTable
        data={educationsDetails}
        onSearch={debounce(handleSearch, DEBOUNCE_TIMEOUT)}
        onAdd={() => setEducationModal(true)}
        totalRecords={totalRecords}
        addButtonTooltip="Add Education"
        columns={EDUCATIONS_COLUMNS}
        onPagination={handlePagination}
        // onSort={handleSort}
      />

      {educationModal && (
        <AddEditEducation
          show={educationModal}
          onHide={() => {
            setEducationModal(false);
            setSelectedEducation(null);
          }}
          selectedEducation={selectedEducation}
        />
      )}
    </>
  );
}

export default EducationDetails;
