// Constants
import { API_REQUEST } from '../constants/general';
import { JOB_API, PROFILE_API, USER_API } from '../constants/configs';

// Utility Functions
import { Actions } from '../redux/actions';
import API from '../utils/api';
import { dispatch } from '../utils/store';
import { handleError, handleSuccess } from '../utils/toast';

export const getResumeData = (setResumeOptions, setError) => {
  dispatch(Actions.User.SetLoading, true);
  API(API_REQUEST.get, `${USER_API}/list-resumes`)
    .then((res) => {
      dispatch(Actions.User.SetLoading, false);
      const resumes = res?.data?.data?.data?.resumes;
      const modifiedRecords = resumes.map((resume) => ({
        label: resume.ResumeName,
        value: resume.ID,
        ...resume
      }));
      setResumeOptions(modifiedRecords);
    })
    .catch((error) => {
      dispatch(Actions.User.SetLoading, false);
      console.log(error);
    });
};
export const getEducationData = (setQualificationOptions) => {
  dispatch(Actions.User.SetLoading, true);
  API(API_REQUEST.get, `${USER_API}/list-education`)
    .then((res) => {
      const educations = res?.data?.data?.data?.education;
      const modifiedRecords = educations.map((education) => ({
        label: education.Details,
        value: education.ID,
        ...education
      }));
      setQualificationOptions(modifiedRecords);
      dispatch(Actions.User.SetLoading, false);
    })
    .catch((error) => {
      dispatch(Actions.User.SetLoading, false);
      console.log(error);
    });
};
export const getProfileData = (updatedData) => {
  dispatch(Actions.User.SetLoading, true);
  API(API_REQUEST.get, PROFILE_API)
    .then((res) => {
      dispatch(Actions.User.SetLoading, false);
      updatedData(res?.data?.data?.data);
    })
    .catch((error) => {
      dispatch(Actions.User.SetLoading, false);
      console.log(error);
    });
};
export const getApplyJobDetailsData = (search, pageNumber, size, sortBy, orderBy, toastRef) => {
  dispatch(Actions.User.SetLoading, true);
  const params = {
    ...(!!search ? { searchName: search } : {}),
    ...(!!sortBy ? { sortBy } : {}),
    ...(!!orderBy ? { orderBy } : {}),
    ...(!!pageNumber ? { page: pageNumber, size: size } : {})
  };

  API(API_REQUEST.get, `${JOB_API}/applications`, { params })
    .then((res) => {
      handleSuccess(toastRef, res);
      dispatch(Actions.User.SetLoading, false);
      dispatch(Actions.ApplyJobs.SetApplyJobs, res?.data?.data?.data || []);
      dispatch(Actions.ApplyJobs.SetTotalRecords, res?.data?.data?.count.totalRecords || 0);
    })
    .catch((error) => {
      dispatch(Actions.User.SetLoading, false);
      console.log(error);
    });
};

export const AddApplyJobDetails = (selectedJob, data, CloseDialog, toastRef) => {
  dispatch(Actions.User.SetLoading, true);
  API(API_REQUEST.post, `${JOB_API}/${selectedJob}/apply`, data)
    .then((res) => {
      handleSuccess(toastRef, res);
      dispatch(Actions.User.SetLoading, false);
      CloseDialog();
    })
    .catch((error) => {
      handleError(toastRef, error);
      console.log(error);
      dispatch(Actions.User.SetLoading, false);
    });
};
