// Constants
import { APPLY_MESSAGE, CAREER_EMAIL, EMAIL_TITLE, TEAM_MESSAGE } from '../constants/homePage';

function CareerInfo() {
  return (
    <section className="career-info-section">
      <div className="container">
        <div className="talented-team-block">
          <p>{TEAM_MESSAGE}
          </p>
          <p>
            {APPLY_MESSAGE}
            <a href={`mailto:${CAREER_EMAIL}`} title={EMAIL_TITLE}>
              {CAREER_EMAIL}
            </a>
            .
          </p>
        </div>
      </div>
    </section>
  );
}

export default CareerInfo;
