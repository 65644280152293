import { useEffect } from 'react';

// Utility Functions
import { getLocalStorageUserData } from '../../utils/session';
import { handleError, handleSuccess } from '../../utils/toast';
import { dispatch } from '../../utils/store';
import API from '../../utils/api';
import { Actions } from '../../redux/actions';
import { formatTime, getFormattedDateView, wordCapitalize } from '../../utils/general';

// Constants
import {
  API_REQUEST,
  HR_OVERALL_FEEDBACK_STATUS,
  INTERVIEW_STATUS_LIST,
  INTERVIEWS_COLUMNS,
  InterviewType,
  InterviewTypeValue,
  INVITATION_STATUS,
  PRACTICAL_RESULT_STATUS,
  STATUS_INTERVIEW
} from '../../constants/general';
import { INTERVIEW_ROUND_API } from '../../constants/configs';

// Utility Packages
import { useSelector } from 'react-redux';
import { Badge } from 'primereact/badge';

// Components
import CustomDataTable from '../../components/DataTable';

function Interviews() {
  // Store
  const toastReff = useSelector((state) => state.notifications.toastReff);
  const { interviews, pageNumber, size, totalRecords, sortBy, orderBy } = useSelector(
    (state) => state.interviews
  );

  useEffect(() => {
    getInterviewData(pageNumber, size, sortBy, orderBy);
  }, [pageNumber, size, sortBy, orderBy]); // eslint-disable-line react-hooks/exhaustive-deps

  const updateTheData = (interviews) => {
    const modifiedInterviewRoundDetails = interviews?.map((interviewData, index) => {
      const interviewers =
        interviewData?.InterviewRoundInvitations &&
        interviewData?.InterviewRoundInvitations?.length &&
        interviewData?.InterviewRoundInvitations.filter(
          (interviewerDetails) =>
            interviewerDetails?.Status === INVITATION_STATUS.PENDING ||
            interviewerDetails?.Status === INVITATION_STATUS.ACCEPT
        );
      let interviewersName = '';
      if (interviewers && interviewers?.length) {
        interviewersName = interviewers
          .map((interviewerDetails) => {
            return (
              interviewerDetails?.Invited?.FirstName + ' ' + interviewerDetails?.Invited?.LastName
            );
          })
          ?.join(', ');
      }
      return {
        ...interviewData,
        'Interviewer Name': wordCapitalize(interviewersName),
        'Start Date': `${getFormattedDateView(
          interviewData.StartDateOfInterviewRound
        )} ${formatTime(interviewData?.StartDateOfInterviewRound)}`,
        HR: `${interviewData?.HROfRound?.FirstName} ${interviewData?.HROfRound?.LastName}`,

        InterviewRound: `${InterviewTypeValue[interviewData?.InterviewRound]}`,
        Status: (
          <div key={index} className="center interview-status">
            <Badge
              className={`white-space-nowrap ${
                interviewData?.Status === STATUS_INTERVIEW.TECHNICAL_INTERVIEW_1_SCHEDULE ||
                interviewData?.Status === STATUS_INTERVIEW.TECHNICAL_INTERVIEW_2_SCHEDULE ||
                interviewData?.Status === STATUS_INTERVIEW.MANAGEMENT_SCHEDULE ||
                interviewData?.Status === STATUS_INTERVIEW.PRACTICAL_SCHEDULE ||
                interviewData?.Status === STATUS_INTERVIEW.HR_SCHEDULE
                  ? 'schedule-badge'
                  : interviewData?.Status === STATUS_INTERVIEW.TECHNICAL_INTERVIEW_1_RESCHEDULE ||
                      interviewData?.Status === STATUS_INTERVIEW.TECHNICAL_INTERVIEW_2_RESCHEDULE ||
                      interviewData?.Status === STATUS_INTERVIEW.MANAGEMENT_RESCHEDULE ||
                      interviewData?.Status === STATUS_INTERVIEW.HR_RESCHEDULE ||
                      interviewData?.Status === STATUS_INTERVIEW.PRACTICAL_RESCHEDULE
                    ? 'reschedule-badge'
                    : interviewData?.Status === STATUS_INTERVIEW.TECHNICAL_INTERVIEW_1_COMPLETE ||
                        interviewData?.Status === STATUS_INTERVIEW.TECHNICAL_INTERVIEW_2_COMPLETE ||
                        interviewData?.Status === STATUS_INTERVIEW.MANAGEMENT_COMPLETE ||
                        interviewData?.Status === STATUS_INTERVIEW.PRACTICAL_COMPLETE ||
                        interviewData?.Status === STATUS_INTERVIEW.HR_COMPLETE
                      ? 'complete-badge'
                      : interviewData?.Status === STATUS_INTERVIEW.CANCELLED
                        ? 'cancel-badge'
                        : 'pending-badge'
              }`}
              value={INTERVIEW_STATUS_LIST[interviewData?.Status]}
            />
          </div>
        ),
        Result: interviewData?.Result
          ? interviewData?.InterviewRound === InterviewType.Practical
            ? PRACTICAL_RESULT_STATUS[interviewData?.Result]
            : HR_OVERALL_FEEDBACK_STATUS[interviewData?.Result]
          : ''
      };
    });

    return modifiedInterviewRoundDetails;
  };

  const getInterviewData = (pageNumber, size, sortBy, orderBy) => {
    const userData = getLocalStorageUserData();
    const params = {
      ...(!!sortBy ? { sortBy } : {}),
      ...(!!userData?.id ? { id: userData?.id } : {}),
      ...(!!orderBy ? { orderBy } : {}),
      ...(!!pageNumber ? { page: pageNumber, pageSize: size } : {})
    };

    dispatch(Actions.User.SetLoading, true);
    API(API_REQUEST.get, `${INTERVIEW_ROUND_API}`, { params })
      .then((res) => {
        handleSuccess(toastReff, res);
        dispatch(Actions.Interviews.SetInterviews, updateTheData(res?.data?.data?.data));
        dispatch(Actions.Interviews.SetTotalRecords, res?.data?.data?.count);
        dispatch(Actions.User.SetLoading, false);
      })
      .catch((error) => {
        dispatch(Actions.User.SetLoading, false);
        handleError(toastReff, error);
        console.log(error);
      });
  };

  const handlePagination = (pageNumber, size) => {
    dispatch(Actions.Interviews.SetPageNumber, pageNumber);
    dispatch(Actions.Interviews.SetPageSize, size);
  };

  const handleSort = () => {
    dispatch(Actions.Interviews.SetOrderBy, orderBy === 'ASC' ? 'DESC' : 'ASC');
  };

  return (
    <div className="interview-container">
      <div className="interview-page-wrapper">
        <div className="legends">
          <div className="legend-label">
            <span className="pending-label" />
            Pending
          </div>
          <div className="legend-label">
            <span className="schedule-label" />
            Schedule
          </div>
          <div className="legend-label">
            <span className="reschedule-label" />
            Reschedule
          </div>
          <div className="legend-label">
            <span className="complete-label" />
            Completed
          </div>
          <div className="legend-label">
            <span className="cancel-label" />
            Cancel
          </div>
        </div>
        <CustomDataTable
          name="Interviews"
          data={interviews}
          totalRecords={totalRecords}
          columns={INTERVIEWS_COLUMNS}
          onPagination={handlePagination}
          onSort={handleSort}
        />
      </div>
    </div>
  );
}

export default Interviews;
