import React from 'react';

// Constants
import { employmentValidationSchema } from '../constants/schemas';
import { getFormErrorMessage, isFormFieldValid } from '../utils/general';
import { EMPLOYMENT_TYPE, LABELS } from '../constants/general';

// Components
import Label from './Label';

// Utility Packages
import { classNames } from 'primereact/utils';
import { Sidebar } from 'primereact/sidebar';
import { Button } from 'primereact/button';
import { useSelector } from 'react-redux';
import { useFormik } from 'formik';
import { RadioButton } from 'primereact/radiobutton';
import { InputText } from 'primereact/inputtext';
import { InputNumber } from 'primereact/inputnumber';
import { Dropdown } from 'primereact/dropdown';
import DatePicker from 'react-datepicker';

// Utility Functions
import { updateEmploymentDetails } from '../middleware/employmentDetails';

const AddEditEmploymentDetails = (props) => {
  const { onHide, show, selectedEmployment } = props;

  // Store
  const toastReff = useSelector((state) => state.notifications.toastReff);
  const { search, pageNumber, size } = useSelector((state) => state.employmentDetails);
  const formik = useFormik({
    initialValues: {
      jobTitle: selectedEmployment?.JobTitle || '',
      currentEmployment: selectedEmployment?.CurrentEmployment || '',
      employmentType: selectedEmployment?.EmploymentType || '',
      totalExperience: selectedEmployment?.TotalExperience || '',
      companyName: selectedEmployment?.CompanyName || '',
      jobLocation: selectedEmployment?.JobLocation || '',
      startDate: selectedEmployment?.StartDate || '',
      endDate: selectedEmployment?.EndDate || '',
      joiningSalary: selectedEmployment?.JoiningSalary || '',
      lastSalary: selectedEmployment?.LastSalary || ''
    },
    validationSchema: employmentValidationSchema,
    onSubmit: (values) => {
      updateEmploymentDetails(
        selectedEmployment,
        values,
        search,
        pageNumber,
        size,
        onHide,
        toastReff
      );
    }
  });

  return (
    <>
      <Sidebar
        visible={show}
        onHide={onHide}
        position="right"
        className="sidebar-drawer employment-sidebar">
        <div className="form-box-wrapper change-password-form add-edit-employment-wrapper">
          <div className="title-wrapper">
            <p className="card-title">Employment</p>
            <button className="p-sidebar-close" onClick={onHide}>
              <span className="p-sidebar-close-icon pi pi-times" />
            </button>
          </div>
          <form onSubmit={formik.handleSubmit} className="p-fluid">
            <div className="form-row-wrapper form-row-wrap">
              <div className="form-col full-width">
                <div className="form-group-outer">
                  <div className="custom-form-group">
                    <Label htmlFor="jobTitle" text={LABELS.INPUT.JOB_TITLE} isBold isMandatory />
                    <InputText
                      id="jobTitle"
                      name="jobTitle"
                      placeholder={LABELS.INPUT.JOB_TITLE}
                      value={formik.values.jobTitle}
                      onChange={formik.handleChange}
                      className={classNames({
                        'p-invalid': isFormFieldValid(formik, 'jobTitle')
                      })}
                    />
                  </div>
                  {getFormErrorMessage(formik, 'jobTitle')}
                </div>
              </div>
              <div className="form-col full-width">
                <div className="form-group-outer">
                  <div className="custom-form-group margin-bottom">
                    <Label
                      htmlFor="currentEmployment"
                      text={LABELS.INPUT.CURRENT_EMPLOYMENT}
                      isBold
                      isMandatory
                    />
                    <div className="radio-list-wrapper">
                      <div className="form-group-outer-radiobutton">
                        <RadioButton
                          inputId="Yes"
                          value="1"
                          name="currentEmployment"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          checked={formik.values.currentEmployment === '1'}
                        />
                        <Label htmlFor="Yes" text={LABELS.INPUT.YES} isBold />
                      </div>
                      <div className="form-group-outer-radiobutton">
                        <RadioButton
                          inputId="No"
                          value="2"
                          name="currentEmployment"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          checked={formik.values.currentEmployment === '2'}
                        />
                        <Label htmlFor="No" text={LABELS.INPUT.NO} isBold />
                      </div>
                    </div>
                  </div>
                  {getFormErrorMessage(formik, 'currentEmployment')}
                </div>
              </div>
              <div className="form-col full-width">
                <div className="form-group-outer">
                  <div className="custom-form-group">
                    <Label
                      htmlFor="employmentType"
                      text={LABELS.INPUT.EMPLOYMENT_TYPE}
                      isBold
                      isMandatory
                    />
                    <Dropdown
                      id="employmentType"
                      name="employmentType"
                      value={formik.values.employmentType}
                      optionLabel="name"
                      optionValue="code"
                      options={EMPLOYMENT_TYPE}
                      onChange={formik.handleChange}
                      panelClassName="custom-dropdown-panel"
                      className={classNames({
                        'p-invalid': isFormFieldValid(formik, 'employmentType'),
                        'w-full': true
                      })}
                    />
                  </div>
                  {getFormErrorMessage(formik, 'employmentType')}
                </div>
              </div>
              <div className="form-col full-width">
                <div className="form-group-outer">
                  <div className="custom-form-group">
                    <Label
                      htmlFor="totalExperience"
                      text={LABELS.INPUT.TOTAL_EXP}
                      isBold
                      isMandatory
                    />
                    <InputNumber
                      id="totalExperience"
                      name="totalExperience"
                      placeholder={LABELS.INPUT.TOTAL_EXP_PLACEHOLDER}
                      value={formik.values.totalExperience}
                      className={classNames({
                        'p-invalid': isFormFieldValid(formik, 'totalExperience')
                      })}
                      onValueChange={(e) => formik.setFieldValue('totalExperience', e.value)}
                      mode="decimal"
                      minFractionDigits={2}
                      maxFractionDigits={2}
                    />
                  </div>
                  {getFormErrorMessage(formik, 'totalExperience')}
                </div>
              </div>
              <div className="form-col full-width">
                <div className="form-group-outer">
                  <div className="custom-form-group">
                    <Label
                      htmlFor="companyName"
                      text={LABELS.INPUT.COMPANY_NAME}
                      isBold
                      isMandatory
                    />
                    <InputText
                      id="companyName"
                      name="companyName"
                      placeholder={LABELS.INPUT.COMPANY_NAME}
                      value={formik.values.companyName}
                      onChange={formik.handleChange}
                      className={classNames({
                        'p-invalid': isFormFieldValid(formik, 'companyName')
                      })}
                    />
                  </div>
                  {getFormErrorMessage(formik, 'companyName')}
                </div>
              </div>
              <div className="form-col full-width">
                <div className="form-group-outer">
                  <div className="custom-form-group">
                    <Label
                      htmlFor="jobLocation"
                      text={LABELS.INPUT.JOB_LOCATION}
                      isBold
                      isMandatory
                    />
                    <InputText
                      id="jobLocation"
                      name="jobLocation"
                      placeholder={LABELS.INPUT.JOB_LOCATION}
                      value={formik.values.jobLocation}
                      onChange={formik.handleChange}
                      className={classNames({
                        'p-invalid': isFormFieldValid(formik, 'jobLocation')
                      })}
                    />
                  </div>
                  {getFormErrorMessage(formik, 'jobLocation')}
                </div>
              </div>
              <div className="form-col">
                <div className="form-group-outer">
                  <div className="custom-form-group date-picker-wrapper">
                    <Label htmlFor="startDate" text={LABELS.INPUT.START_DATE} isBold isMandatory />
                    <DatePicker
                      id="startDate"
                      name="startDate"
                      dateFormat="dd/MM/yyyy"
                      placeholderText="Start Date"
                      selected={formik.values.startDate}
                      onChange={(date) => formik.setFieldValue('startDate', date)}
                      maxDate={new Date()}
                      onKeyDown={(e) => {
                        e.preventDefault();
                      }}
                      peekNextMonth
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                      className={classNames({
                        'p-invalid': isFormFieldValid(formik, 'startDate')
                      })}
                    />
                  </div>
                  {getFormErrorMessage(formik, 'startDate')}
                </div>
              </div>
              <div className="form-col">
                <div className="form-group-outer">
                  <div className="custom-form-group date-picker-wrapper">
                    <Label htmlFor="endDate" text={LABELS.INPUT.END_DATE} isBold isMandatory />
                    <DatePicker
                      id="endDate"
                      name="endDate"
                      dateFormat="dd/MM/yyyy"
                      placeholderText="End Date"
                      selected={formik.values.endDate}
                      onChange={(date) => formik.setFieldValue('endDate', date)}
                      maxDate={new Date()}
                      peekNextMonth
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                      onKeyDown={(e) => {
                        e.preventDefault();
                      }}
                      className={classNames({
                        'p-invalid': isFormFieldValid(formik, 'endDate')
                      })}
                    />
                  </div>
                  {getFormErrorMessage(formik, 'endDate')}
                </div>
              </div>
              <div className="form-col">
                <div className="form-group-outer">
                  <div className="custom-form-group">
                    <Label
                      htmlFor="joiningSalary"
                      text={LABELS.INPUT.JOINING_SALARY}
                      isBold
                      isMandatory
                    />
                    <div className="p-inputgroup flex-1 salary-wrapper">
                      <InputNumber
                        id="joiningSalary"
                        name="joiningSalary"
                        placeholder={LABELS.INPUT.JOINING_SALARY}
                        value={formik.values.joiningSalary}
                        className={classNames({
                          'p-invalid': isFormFieldValid(formik, 'joiningSalary')
                        })}
                        onValueChange={(e) => formik.setFieldValue('joiningSalary', e.value)}
                        mode="decimal"
                        min={0}
                        max={100}
                        minFractionDigits={1}
                        maxFractionDigits={1}
                      />
                      <span className="p-inputgroup-addon">LPA</span>
                    </div>
                  </div>
                  {getFormErrorMessage(formik, 'joiningSalary')}
                </div>
              </div>
              <div className="form-col">
                <div className="form-group-outer">
                  <div className="custom-form-group">
                    <Label
                      htmlFor="lastSalary"
                      text={LABELS.INPUT.LAST_SALARY}
                      isBold
                      isMandatory
                    />
                    <div className="p-inputgroup flex-1 salary-wrapper">
                      <InputNumber
                        id="lastSalary"
                        name="lastSalary"
                        placeholder={LABELS.INPUT.JOINING_SALARY}
                        value={formik.values.lastSalary}
                        className={classNames({
                          'p-invalid': isFormFieldValid(formik, 'lastSalary')
                        })}
                        onValueChange={(e) => formik.setFieldValue('lastSalary', e.value)}
                        mode="decimal"
                        min={0}
                        max={100}
                        minFractionDigits={1}
                        maxFractionDigits={1}
                      />
                      <span className="p-inputgroup-addon">LPA</span>
                    </div>
                  </div>
                  {getFormErrorMessage(formik, 'lastSalary')}
                </div>
              </div>
            </div>
            <div className="form-btn-wrapper">
              <Button
                type="button"
                variant="contained"
                className="ims-blue-btn"
                onClick={formik.handleSubmit}>
                {LABELS.BUTTON.SAVE}
              </Button>
              <Button
                variant="contained"
                onClick={onHide}
                color="error"
                className="gray-btn border-btn">
                {LABELS.BUTTON.CANCEL}
              </Button>
            </div>
          </form>
        </div>
      </Sidebar>
    </>
  );
};

export default AddEditEmploymentDetails;
