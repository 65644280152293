import { useEffect, useState } from 'react';
// Utility Packages
import { Link } from 'react-router-dom';
import { Card } from 'primereact/card';

// Constants
import {
  EXPERIENCE,
  JOB_APPLY_NOW,
  JOB_READ_MORE,
  JOBS_TITLE,
  POSITION
} from '../constants/homePage';
import { JOB_API } from '../constants/configs';
import { API_REQUEST, ROUTES } from '../constants/general';

// Utility Functions
import API from '../utils/api';

function JobsAtTatvasoft() {
  const [jobs, setJobs] = useState([]);
  const jobImagePath = `${process.env.REACT_APP_PROFILE_IMAGE_URL}/public/uploads/job`;

  useEffect(() => {
    const params = { size: 1000, page: 1 };
    API(API_REQUEST.get, JOB_API, { params })
      .then((res) => {
        const { data } = res?.data?.data || {};
        const latestJob = data.length > 3 ? data?.slice(-3) : data;
        setJobs(latestJob || []);
      })
      .catch((error) => {
        console.error('Error fetching jobs:', error);
      });
  }, []);

  return (
    <section className="tatva-job-section">
      <div className="container">
        <div className="title-block">
          <h2>{JOBS_TITLE}</h2>
        </div>
        <div className="job-grid-wrapper">
          {jobs.map((job, index) => (
            <div className="job-grid" key={index}>
              <Card>
                <em>
                  <img src={`${jobImagePath}/${job.ID}/${job.JobImage}`} alt={job.JobTitle} />
                </em>
                <h3 className="capitalize">{job.JobTitle}</h3>
                <div className="exp-wrapper">
                  <p>
                    {POSITION} : <span>{job.Position || '--'}</span>
                  </p>
                  <p>
                    {EXPERIENCE} : <span>{job.Experience || '--'} Y</span>
                  </p>
                </div>
                <div className="btn-wrapper">
                  <Link to={`/job/${job.ID}`} title={JOB_READ_MORE} className="btn read-more-btn">
                    {JOB_READ_MORE}
                  </Link>
                  <Link to={ROUTES.LOGIN} title={JOB_APPLY_NOW} className="btn yellow-btn">
                    {JOB_APPLY_NOW}
                  </Link>
                </div>
              </Card>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

export default JobsAtTatvasoft;
