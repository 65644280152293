import { useEffect, useRef } from 'react';

// Constants
import { ROUTES } from '../constants/general';

// Components
import Login from '../pages/auth/Login';
import Register from '../pages/auth/Register';
import Home from '../pages/auth/Home';
import Dashboard from '../pages/dashboard/Dashboard';
import ResetPassword from '../pages/auth/ResetPassword';
import FAQs from '../pages/dashboard/FAQs';
import InterviewProccess from '../pages/dashboard/InterviewProccess';
import ForgotPassword from '../pages/auth/ForgotPassword';
import Layout from '../components/Layout';
import Profile from '../pages/dashboard/Profile';
import Interviews from '../pages/dashboard/Interviews';
import Loader from '../components/Loader';
import Jobs from '../pages/dashboard/Jobs';
import JobDetails from '../pages/auth/JobDetails';

// Utility Functions
import PublicRoute from './PublicRoute';
import PrivateRoute from './PrivateRoute';

// Utility Packages
import { Toast } from 'primereact/toast';
import { useSelector } from 'react-redux';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

// Store
import { dispatch } from '../utils/store';
import { Actions } from '../redux/actions';
import AppliedJobs from '../pages/dashboard/AppliedJobs';

export default function Router() {
  const toast = useRef(null);

  // Store
  const { loading } = useSelector((state) => state.auth);

  useEffect(() => {
    dispatch(Actions.Notifications.SetNotificationsRef, toast);
  }, []);

  return (
    <>
      {loading && <Loader />}
      <Toast ref={toast} position="bottom-right" />
      <BrowserRouter>
        <Routes>
          <Route
            path={ROUTES.HOME}
            exact
            element={
              <PublicRoute>
                <Home />
              </PublicRoute>
            }
          />
          <Route
            path={ROUTES.REGISTER}
            exact
            element={
              <PublicRoute>
                <Register />
              </PublicRoute>
            }
          />
          <Route
            path={ROUTES.LOGIN}
            exact
            element={
              <PublicRoute>
                <Login />
              </PublicRoute>
            }
          />
          <Route
            path={ROUTES.FORGOT_PASSWORD}
            exact
            element={
              <PublicRoute>
                <ForgotPassword />
              </PublicRoute>
            }
          />
          <Route
            path={ROUTES.RESET_PASSWORD}
            exact
            element={
              <PublicRoute>
                <ResetPassword />
              </PublicRoute>
            }
          />
          <Route
            path={ROUTES.JOB_BY_ID}
            element={
              <PublicRoute>
                <JobDetails />
              </PublicRoute>
            }
          />
          <Route
            path={ROUTES.HOME}
            exact
            element={
              <PrivateRoute>
                <Layout />
              </PrivateRoute>
            }>
            <Route path={ROUTES.DASHBOARD} element={<Dashboard />} />
            <Route path={ROUTES.INTERVIEWS} element={<Interviews />} />
            <Route path={ROUTES.PROFILE} element={<Profile />} />
            <Route path={ROUTES.FAQS} element={<FAQs />} />
            <Route path={ROUTES.INTERVIEW_PROCCESS} element={<InterviewProccess />} />
            <Route path={ROUTES.JOBS} element={<Jobs />} />
            <Route path={ROUTES.APPLY_JOBS} element={<AppliedJobs />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </>
  );
}
