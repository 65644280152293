//Constants
import { JOB_API } from '../constants/configs';
import { API_REQUEST } from '../constants/general';
// Utility Functions
import { Actions } from '../redux/actions';
import API from '../utils/api';
import { dispatch } from '../utils/store';
import { handleError, handleSuccess } from '../utils/toast';

export const getJobDetailsData = (search, pageNumber, size, sortBy, orderBy, toastRef) => {
  dispatch(Actions.User.SetLoading, true);
  const params = {
    ...(!!search ? { searchName: search } : {}),
    ...(!!sortBy ? { sortBy } : {}),
    ...(!!orderBy ? { orderBy } : {}),
    ...(!!pageNumber ? { page: pageNumber, size: size } : {})
  };

  API(API_REQUEST.get, `${JOB_API}`, { params })
    .then((res) => {
      handleSuccess(toastRef, res);
      dispatch(Actions.User.SetLoading, false);
      dispatch(Actions.Jobs.SetJobs, res?.data?.data?.data || []);
      dispatch(Actions.Jobs.SetTotalRecords, res?.data?.data?.count.totalRecords || 0);
    })
    .catch((error) => {
      dispatch(Actions.User.SetLoading, false);
      handleError(toastRef, error);
      console.log(error);
    });
};

export const getJobDetails = (id, toastRef) => {
  dispatch(Actions.User.SetLoading, true);
  API(API_REQUEST.get, `${JOB_API}/${id}`)
    .then((res) => {
      handleSuccess(toastRef, res);
      dispatch(Actions.User.SetLoading, false);
      dispatch(Actions.Jobs.SetJobPageData, res?.data?.data?.data || {});
    })
    .catch((error) => {
      dispatch(Actions.User.SetLoading, false);
      handleError(toastRef, error);
      console.log(error);
    });
};
