// Constants
import {
  CTA_BUTTON_TEXT,
  CTA_EMAIL,
  CTA_EMAIL_TITLE,
  CTA_HEADING,
  CTA_PHONE,
  CTA_PHONE_TITLE,
  CTA_REQUEST_TITLE
} from '../constants/homePage';

function CtaBlock() {
  return (
    <section className="cta-block">
      {/* <div className="container">
        <div className="cta-block-inner">
          <div className="content-block">
            <h2>{CTA_HEADING}</h2>
            <p>
              Send us your requirements at{' '}
              <a href={`mailto:${CTA_EMAIL}`} title={CTA_EMAIL_TITLE}>
                {CTA_EMAIL}
              </a>{' '}
              or call{' '}
              <a href={`tel:${CTA_PHONE}`} title={CTA_PHONE_TITLE}>
                <span>{CTA_PHONE}</span>
              </a>
            </p>
          </div>
          <div className="btn-wrapper">
            <a href="#" title={CTA_REQUEST_TITLE} className="btn yellow-btn">
              {CTA_BUTTON_TEXT}
            </a>
          </div>
        </div>
      </div> */}
    </section>
  );
}

export default CtaBlock;
