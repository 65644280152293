import { useEffect, useState } from 'react';

// Components
import AddEditResume from '../../components/AddEditResume';
import CustomDataTable from '../../components/DataTable';

// Constants
import { DEBOUNCE_TIMEOUT, RESUMES_COLUMNS } from '../../constants/general';

// Utility Functions
import { deleteResume, getResumeDetailsData } from '../../middleware/resumeDetails';
import { openResumeLink } from '../../utils/general';

// Utility Packages
import { Actions } from '../../redux/actions';
import { debounce } from '../../utils/debounce';
import { dispatch } from '../../utils/store';
import { useSelector } from 'react-redux';
import { Button } from 'primereact/button';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import { ConfirmDialog } from 'primereact/confirmdialog';

// images
import viewIcon from '../../assets/images/visibility-on.svg';
import editIcon from '../../assets/images/grid-edit-icon.svg';
import deleteIcon from '../../assets/images/grid-delete-icon.svg';
// import downloadIcon from '../../assets/images/grid-download-icon.svg';

function ResumeDetails() {
  // Store
  const toastReff = useSelector((state) => state.notifications.toastReff);
  const { resumes, totalRecords, search, pageNumber, size } = useSelector(
    (state) => state.resumeDetails
  );

  // State
  const [resumeDetailsModal, setResumeDetailsModal] = useState(false);
  const [resumesDetails, setResumeDetails] = useState([]);
  const [selectedResume, setSelectedResume] = useState(null);
  const [deleteResumeModal, setDeleteResumeModal] = useState(false);

  useEffect(
    () => getResumeDetailsData(search, pageNumber, size, toastReff),
    [search, pageNumber, size]
  );

  const onEditClick = (resume) => {
    setSelectedResume(resume);
    setResumeDetailsModal(true);
  };

  const onDeleteClick = (resume) => {
    setSelectedResume(resume);
    setDeleteResumeModal(true);
  };

  useEffect(() => {
    return () => {
      dispatch(Actions.Resumes.ResumesReset);
    };
  }, []);

  useEffect(() => {
    const modifiedRecords = resumes?.map((resume) => {
      return {
        ...resume,
        action: (
          <div className="table-actions-wrapper">
            <Button
              data-tooltip-id="view-button"
              data-tooltip-content="View"
              rounded
              text
              severity="secondary"
              aria-label="View"
              onClick={() => openResumeLink(`${resume?.FilePath}`)}
              className="grid-action-btn">
              <em>
                <img src={viewIcon} alt="view icon" />
              </em>
            </Button>
            {/* <Button
              data-tooltip-id="download-button"
              data-tooltip-content="Download"
              // icon="pi pi-fw pi-download"
              rounded
              text
              severity="secondary"
              aria-label="Download"
              // onClick={() => openResumeLink(`${resume?.FilePath}`)}
              className="grid-action-btn">
              <em>
                <img src={downloadIcon} alt="download icon" />
              </em>
            </Button> */}
            <Button
              data-tooltip-id="edit-button"
              data-tooltip-content="Edit"
              rounded
              text
              severity="secondary"
              aria-label="Edit"
              onClick={() => onEditClick(resume)}
              className="grid-action-btn">
              <em>
                <img src={editIcon} alt="download icon" />
              </em>
            </Button>
            <Button
              data-tooltip-id="delete-button"
              data-tooltip-content="Delete"
              rounded
              text
              aria-label="Delete"
              onClick={() => onDeleteClick(resume)}
              className="grid-action-btn">
              <em>
                <img src={deleteIcon} alt="delete icon" />
              </em>
            </Button>
            <ReactTooltip id="view-button" place="top" />
            <ReactTooltip id="download-button" place="top" />
            <ReactTooltip id="edit-button" place="top" />
            <ReactTooltip id="delete-button" place="top" />
          </div>
        )
      };
    });
    setResumeDetails(modifiedRecords);
  }, [resumes]);

  const handlePagination = (pageNumber, size) => {
    dispatch(Actions.Resumes.SetPageNumber, pageNumber);
    dispatch(Actions.Resumes.SetPageSize, size);
  };

  const handleSearch = (searchValue) => {
    dispatch(Actions.Resumes.SetSearch, searchValue);
  };
  return (
    <>
      <ConfirmDialog
        header="Delete"
        message="Are you sure you want to delete?"
        icon="pi pi-exclamation-triangle"
        visible={deleteResumeModal}
        onHide={() => {
          setSelectedResume(null);
          setDeleteResumeModal(false);
        }}
        accept={() =>
          deleteResume(
            selectedResume?.ID,
            search,
            pageNumber,
            size,
            toastReff,
            setDeleteResumeModal
          )
        }
      />
      <CustomDataTable
        data={resumesDetails}
        onSearch={debounce(handleSearch, DEBOUNCE_TIMEOUT)}
        onAdd={() => setResumeDetailsModal(true)}
        totalRecords={totalRecords}
        addButtonTooltip="Add Resume"
        columns={RESUMES_COLUMNS}
        onPagination={handlePagination}
        // onSort={handleSort}
      />

      {resumeDetailsModal && (
        <AddEditResume
          show={resumeDetailsModal}
          onHide={() => {
            setResumeDetailsModal(false);
            setSelectedResume(null);
          }}
          selectedResume={selectedResume}
        />
      )}
    </>
  );
}

export default ResumeDetails;
