// images for sidebar
import dashboardIcon from '../assets/images/sidebar-dashboard-icon.svg';
import editProfileIcon from '../assets/images/sidebar-edit-profile-icon.svg';
import interViewIcon from '../assets/images/sidebar-interview-icon.svg';
import interViewProcessIcon from '../assets/images/sidebar-interview-process-icon.svg';
import jobDesignationIcon from '../assets/images/sidebar-job-designation-icon.svg';
import faqIcon from '../assets/images/sidebar-faq-icon.svg';

export const LABELS = {
  WELCOME: 'Welcome to IMS portal!',
  KEEP_ME_SIGNED_IN: 'Keep me signed in',
  POWERED_BY: 'Powered by',
  FORGOT_PASSWORD: 'Forgot Password',
  RESET_PASSWORD: 'Reset Password',
  BACK_TO_LOGIN: 'Back to login',
  FORGOT_PASSWORD_LABEL:
    'Enter your email and we will send you instructions to reset your password',
  BUTTON: {
    SIGN_UP: 'Sign Up',
    SIGN_IN: 'Sign In',
    SEND: 'Send',
    SAVE: 'Save',
    UPDATE_PASSWORD: 'Update Password',
    CANCEL: 'Cancel',
    SAVE_APPLICATION: 'Apply'
  },
  INPUT: {
    FIRSTNAME: 'First Name',
    MIDDLENAME: 'Middle Name',
    LASTNAME: 'Last Name',
    EMAIL: 'Email Address',
    GENDER: 'Gender',
    PHONE: 'Mobile',
    ALT_PHONE: 'Alternative Contact',
    SECOND_PHONE: 'Second Mobile',
    MALE: 'Male',
    FEMALE: 'Female',
    YES: 'Yes',
    NO: 'No',
    ADDRESS: 'Address',
    SINGLE: 'Single',
    MARRIED: 'Married',
    CURRENT_LOCATION: 'Current Location',
    MARITAL_STATUS: 'Marital Status',
    TECHNOLOGY: 'Technology',
    DOB: 'Date of Birth',
    LANGUAGE: 'Language',
    CREATE_PASSWORD: 'Create Password',
    CONFIRM_PASSWORD: 'Confirm Password',
    PASSWORD: 'Password',
    OLD_PASSWORD: 'Old Password',
    NEW_PASSWORD: 'New Password',

    RESUME: 'Resume',
    RESUME_NAME: 'Resume Name',
    RESUME_UPLOAD: 'Upload Resume',
    RESUME_UPDATE: 'Update',

    KEY_SKILLS: 'Key Skills',

    SOFT_SKILLS: 'Soft Skills',

    PROJECT_TITLE: 'Project Title',
    PROJECT_DETAILS: 'Project Details',
    DURATION: 'Duration (Years)',

    EDUCATION_DETAILS: 'Education Details',
    COURSE: 'Course',
    UNIVERSITY: 'University/Institute',
    PASSING_YEAR: 'Passing Year',
    GRADE: 'Grade',
    REMARKS: 'Remarks',

    JOB_TITLE: 'Job Title',
    CURRENT_EMPLOYMENT: 'Current Employment',
    CURRENT_COMPANY: 'Current Company',
    CURRENT_CTC: 'Current CTC',
    EXPEXTED_CTC: 'Expected CTC',
    NOTICE_PERIOD: 'Notice Period',
    HIGH_QUALIFICATION: 'Highest Qualification',
    EMPLOYMENT_TYPE: 'Employment Type',
    TOTAL_EXP: 'Total Exp (Years)',
    TOTAL_EXP_PLACEHOLDER: 'Ex 1.05 (1 Year 5 Months)',
    COMPANY_NAME: 'Company Name',
    JOB_LOCATION: 'Job Location',
    START_DATE: 'Start Date',
    END_DATE: 'End Date',
    JOINING_SALARY: 'Joining Salary',
    LAST_SALARY: 'Last Salary'
  },
  APPLY_JOBS_MSG: {
    PROFILE:
      'Kindly ensure your profile details are complete. You can update them via "Edit Profile".',
    EDUCATION: 'Make sure to fill your education details. You can do this from "Edit Profile".',
    RESUME: 'Make sure to add your resume. You can upload it through "Edit Profile".'
  }
};

export const ROUTES = {
  HOME: '/',
  REGISTER: '/signup',
  LOGIN: '/signin',
  FORGOT_PASSWORD: '/forgot-password',
  RESET_PASSWORD: '/reset-password/:token',
  DASHBOARD: '/dashboard',
  INTERVIEWS: '/interviews',
  PROFILE: '/profile',
  FAQS: '/faqs',
  JOBS: '/jobs',
  APPLY_JOBS: '/apply-jobs',
  JOB_BY_ID: '/job/:id',
  INTERVIEW_PROCCESS: '/interview-proccess'
};

// Message-Types
export const MessageType = {
  Info: 'INFO',
  Error: 'ERROR',
  Warn: 'WARN',
  Success: 'SUCCESS'
};

// Gender
export const Gender = {
  Male: 1,
  Female: 2
};

export const ROLES = {
  ADMIN: 1,
  HR: 2,
  INTERVIEWER: 3,
  CANDIDATE: 4,
  HR_AND_INTERVIEWER: 5
};

export const StatusCode = {
  BadRequest: 400,
  UnAuthorized: 401,
  Forbidden: 403,
  NotFound: 404,
  AlreadyExists: 409,
  InternalServerError: 500
};

export const INITIAL_PAGE_NUMBER = 1;

export const INITIAL_SIZE = 100;
export const INITIAL_SORT = {
  JOB: 'JobTitle',
  APPLY_JOB: 'JobTitle'
};
export const INITIAL_ORDER = {
  JOB: 'ASC',
  APPLY_JOB: 'ASC'
};

// Role
export const API_REQUEST = {
  get: 'GET',
  post: 'POST',
  put: 'PUT',
  delete: 'DELETE'
};

export const ACTION_TYPE = {
  VIEW: 'view',
  EDIT: 'edit',
  DELETE: 'delete',
  CANCEL: 'cancel'
};

export const PAGINATION_OPTIONS = [10, 25, 50, 100];

export const DEBOUNCE_TIMEOUT = 500;

export const APPLY_JOB_NOTE =
'Personal details, highest education, and resume data are visible from Edit Profile section. If you want to make any changes, you need to update them from the Edit Profile section';

export const PROFILE_FORMAT_ERROR ='Please upload an image file (png, jpeg, jpg).';
export const PROFILE_SIZE_ERROR ='Please upload an image file smaller than 1MB.';


export const CANDIDATE_LOCATIONS = [
  { name: 'Gujarat : Ahmedabad', code: 'Gujarat : Ahmedabad' },
  { name: 'Gujarat : Bhavnagar', code: 'Gujarat : Bhavnagar' },
  { name: 'Gujarat : Jamnagar', code: 'Gujarat : Jamnagar' },
  { name: 'Gujarat : Junagadh', code: 'Gujarat : Junagadh' },
  { name: 'Gujarat : Rajkot', code: 'Gujarat : Rajkot' },
  { name: 'Gujarat : Surat', code: 'Gujarat : Surat' },
  { name: 'Gujarat : Vadodara', code: 'Gujarat : Vadodara' },
  { name: 'Gujarat : Gandhinagar', code: 'Gujarat : Gandhinagar' },
  { name: 'Madhya Pradesh : Indore', code: 'Madhya Pradesh : Indore' },
  { name: 'Madhya Pradesh : Bhopal', code: 'Madhya Pradesh : Bhopal' },
  { name: 'Rajasthan : Udaipur', code: 'Rajasthan : Udaipur' },
  { name: 'Rajasthan : Jaipur', code: 'Rajasthan : Jaipur' },
  { name: 'Other', code: 'other' }
];

export const EMPLOYMENT_TYPE = [
  { name: 'Full Time', code: 1 },
  { name: 'Part Time', code: 2 },
  { name: 'Temporary', code: 3 },
  { name: 'Interns', code: 4 },
  { name: 'Other', code: 5 }
];

export const INTERVIEWS_COLUMNS = [
  { field: 'Interviewer Name', header: 'Interviewer Name', class: 'name-col' },
  {
    field: 'Start Date',
    header: 'Date',
    sortBy: 'StartDateOfInterviewRound',
    class: 'text-no-wrap'
  },
  { field: 'HR', header: 'HR' },
  { field: 'InterviewRound', header: 'Interview Round' },
  { field: 'Status', header: 'Status', class: 'status' },
  { field: 'Result', header: 'Result' }
];

export const EDUCATIONS_COLUMNS = [
  {
    field: 'Details',
    header: 'Name',
    class: 'name-col white-space-nowrap',
    headerClass: 'name-col white-space-nowrap'
  },
  { field: 'Course', header: 'Course', class: 'white-space-nowrap' },
  { field: 'University', header: 'University' },
  { field: 'PassingYear', header: 'Passing Year' },
  { field: 'Grade', header: 'Grade' },
  { field: 'action', header: 'Action', class: 'table-action-col', headerClass: 'table-action-col' }
  // { field: 'Remark', header: 'Remark' }
];

export const PROJECT_DETAILS_COLUMNS = [
  { field: 'Title', header: 'Project Title', class: 'name-col', headerClass: 'name-col' },
  { field: 'Technology', header: 'Technology' },
  {
    field: 'Duration',
    header: 'Duration',
    class: 'white-space-nowrap',
    headerClass: 'white-space-nowrap'
  },
  { field: 'action', header: 'Action', class: 'table-action-col', headerClass: 'table-action-col' }
];

export const GRADE = [
  { name: 'A+', code: '1' },
  { name: 'A', code: '2' },
  { name: 'A-', code: '3' },
  { name: 'B+ to A-', code: '9' },
  { name: 'B+', code: '4' },
  { name: 'B to B+', code: '10' },
  { name: 'B', code: '5' },
  { name: 'B- to B', code: '11' },
  { name: 'B-', code: '6' },
  { name: 'C', code: '7' },
  { name: 'N/A', code: '8' }
];

export const GRADE_CODE = {
  1: 'A+',
  2: 'A',
  3: 'A-',
  4: 'B+',
  5: 'B',
  6: 'B-',
  7: 'C',
  9: 'B+ to A-',
  10: 'B to B+',
  11: 'B- to B',
  8: 'N/A'
};

export const NOTICE_PERIOD_LABEL = [
  { label: 'Immediate', value: '0' },
  { label: '1 Month', value: '1' },
  { label: '2 Month', value: '2' },
  { label: '3 Month', value: '3' }
];

export const EMPLOYMENTS_COLUMNS = [
  { field: 'JobTitle', header: 'Name', class: 'name-col' },
  { field: 'CurrentEmployment', header: 'Current Employment' },
  { field: 'EmploymentType', header: 'Employment Type' },
  {
    field: 'TotalExperience',
    header: 'Total Exp.',
    class: 'white-space-nowrap',
    headerClass: 'white-space-nowrap'
  },
  { field: 'CompanyName', header: 'Company Name' },
  { field: 'JobLocation', header: 'Job Location' },
  {
    field: 'StartDate',
    header: 'Start Date',
    class: 'white-space-nowrap',
    headerClass: 'white-space-nowrap'
  },
  {
    field: 'EndDate',
    header: 'End Date',
    class: 'white-space-nowrap',
    headerClass: 'white-space-nowrap'
  },
  { field: 'JoiningSalary', header: 'Joining Salary' },
  { field: 'LastSalary', header: 'Last Salary' },
  { field: 'action', header: 'Action', class: 'table-action-col', headerClass: 'table-action-col' }
];

export const RESUMES_COLUMNS = [
  { field: 'ResumeName', header: 'Resume Name', class: 'name-col', headerClass: 'name-col' },
  // { field: 'FilePath', header: 'Resume' },
  { field: 'action', header: 'Action', class: 'table-action-col', headerClass: 'table-action-col' }
];

export const InterviewType = {
  'Technical Interview - 1': 1,
  Practical: 2,
  'HR Round': 3,
  'Technical Interview - 2': 4,
  'Management Round': 5
};

export const Tab_INDEX = {
  'Personal Details': 0,
  'Resume Management': 1,
  // Skills: 2,
  'Project Details': 2,
  Education: 3,
  Emplopment: 4
};

export const InterviewTypeValue = {
  1: 'Technical Interview - 1',
  2: 'Practical',
  3: 'HR Round',
  4: 'Technical Interview - 2',
  5: 'Management Round'
};

export const PRACTICAL_RESULT_STATUS = {
  1: 'Selected',
  2: 'Not Selected',
  3: 'Further Review'
};

export const HR_OVERALL_FEEDBACK_STATUS = {
  1: 'Selected',
  2: 'Not Selected',
  3: 'Offered',
  4: 'In Discussion',
  5: 'Offer Rejected',
  6: 'Offer Accepted'
};

export const STATUS_INTERVIEW = {
  TECHNICAL_INTERVIEW_1_PENDING: 1,
  TECHNICAL_INTERVIEW_1_SCHEDULE: 2,
  TECHNICAL_INTERVIEW_1_COMPLETE: 3,
  PRACTICAL_PENDING: 4,
  PRACTICAL_SCHEDULE: 5,
  PRACTICAL_COMPLETE: 6,
  HR_PENDING: 7,
  HR_SCHEDULE: 8,
  HR_RESCHEDULE: 21,
  HR_COMPLETE: 9,
  CANCELLED: 10,
  TECHNICAL_INTERVIEW_1_RESCHEDULE: 11,
  PRACTICAL_RESCHEDULE: 12,
  TECHNICAL_INTERVIEW_2_PENDING: 13,
  TECHNICAL_INTERVIEW_2_SCHEDULE: 14,
  TECHNICAL_INTERVIEW_2_RESCHEDULE: 15,
  TECHNICAL_INTERVIEW_2_COMPLETE: 16,
  MANAGEMENT_PENDING: 17,
  MANAGEMENT_SCHEDULE: 18,
  MANAGEMENT_RESCHEDULE: 19,
  MANAGEMENT_COMPLETE: 20
};

export const INTERVIEW_STATUS_LIST = {
  1: 'Technical Interview - 1 Pending',
  2: 'Technical Interview - 1 Schedule',
  11: 'Technical Interview - 1 Reschedule',
  3: 'Technical Interview - 1 Complete',
  13: 'Technical Interview - 2 Pending',
  14: 'Technical Interview - 2 Schedule',
  15: 'Technical Interview - 2 Reschedule',
  16: 'Technical Interview - 2 Complete',
  4: 'Practical Pending',
  5: 'Practical Schedule',
  12: 'Practical Reschedule',
  6: 'Practical Complete',
  17: 'Management Pending',
  18: 'Management Schedule',
  19: 'Management Reschedule',
  20: 'Management Complete',
  7: 'HR Pending',
  8: 'HR Schedule',
  21: 'HR Reschedule',
  9: 'HR Complete',
  10: 'Cancelled'
};

export const INVITATION_STATUS = {
  PENDING: 1,
  ACCEPT: 2,
  REJECT: 3,
  ACCEPT_BY_OTHER: 4,
  AUTO_REJECT: 5,
  RESCHEDULED: 6
};

export const SIDEBAR_MENU = [
  {
    label: 'Home',
    items: [
      {
        label: 'Dashboard',
        // icon: 'pi pi-fw pi-home',
        to: ROUTES.DASHBOARD,
        src: dashboardIcon
      },
      {
        label: 'Edit Profile',
        icon: 'pi pi-fw pi-user-edit',
        to: ROUTES.PROFILE,
        src: editProfileIcon
      },
      {
        label: 'Interviews',
        icon: 'pi pi-fw pi-calendar',
        to: ROUTES.INTERVIEWS,
        src: interViewIcon
      },
      {
        label: 'Interview Process',
        icon: 'pi pi-fw pi-cog',
        to: ROUTES.INTERVIEW_PROCCESS,
        src: interViewProcessIcon
      },
      {
        label: 'Jobs',
        icon: 'pi pi-fw pi-briefcase',
        to: ROUTES.JOBS,
        src: jobDesignationIcon
      },
      {
        label: 'Apply Jobs',
        icon: 'pi pi-fw pi-briefcase',
        to: ROUTES.APPLY_JOBS,
        src: jobDesignationIcon
      },
      {
        label: 'FAQs',
        icon: 'pi pi-fw pi-envelope',
        to: ROUTES.FAQS,
        src: faqIcon
      }
    ]
  }
];

export const JOB_COLUMNS = [
  {
    field: 'JobTitle',
    header: 'Job Title',
    class: 'name-col',
    headerClass: 'name-col',
    sortBy: 'JobTitle'
  },
  {
    field: 'Skills',
    header: 'Skills'
  },
  {
    field: 'Salary',
    header: 'Salary',
    sortBy: 'Salary'
  },
  {
    field: 'Experience',
    header: 'Experience',
    sortBy: 'Experience'
  },
  {
    field: 'Position',
    header: 'Position',
    sortBy: 'Position'
  },
  {
    field: 'action',
    header: 'Action',
    class: 'table-action-col, apply-job-btn',
    headerClass: 'table-action-col'
  }
];

export const APPLY_JOB_COLUMNS = [
  {
    field: 'JobTitle',
    header: 'Job Title',
    class: 'name-col',
    headerClass: 'name-col',
    sortBy: 'JobTitle'
  },
  {
    field: 'Position',
    header: 'Position',
    sortBy: 'Position'
  },
  {
    field: 'Salary',
    header: 'Salary',
    sortBy: 'Salary'
  },
  {
    field: 'ExpectedCTC',
    header: 'Expected CTC',
    sortBy: 'ExpectedCTC'
  }
];
