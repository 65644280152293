import React, { useEffect, useState } from 'react';

// Constsnts
import { ROUTES } from '../constants/general';

// Images
import logo from '../assets/images/site-logo-white.svg';

// Utility Packages
import { Link } from 'react-router-dom';

function Header() {
  const [scroll, setScroll] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setScroll(window.scrollY > 10);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <header className={scroll ? 'site-header sticky-header' : 'site-header'}>
      <div className="header-wrapper">
        <div className="logo-wrapper">
          <a className="white-logo" href="/">
            <img src={logo} alt="logo" />
          </a>
        </div>
        <div className="navigation-wrapper">
          <nav className="main-nav">
            <ul className="menu-navigation">
              {/* {MENU_ITEMS.map((item, index) => (
                <li key={index}>
                  <a title={item.title} href={item.href}>
                    {item.title}
                  </a>
                </li>
              ))} */}
              <li>
                <a title="CONTACT" href={ROUTES.HOME}>
                  contact
                </a>
              </li>
              {/* <li className="contact-wrapper">
                <a title="Contact" className="btn yellow-btn" href="/">
                  Contact
                </a>
              </li>
              <li className="contact-wrapper" style={{ marginLeft: 0 }}>
                <Link title="Contact" className="btn yellow-btn" to={ROUTES.REGISTER}>
                  Sign Up
                </Link>
              </li> */}
              <li className="contact-wrapper" style={{ margin: 0 }}>
                <Link title="Contact" className="btn yellow-btn" to={ROUTES.LOGIN}>
                  Login / Sign Up
                </Link>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </header>
  );
}

export default Header;
