import React from 'react';

// Images
import logoImg from '../../assets/images/site-logo-black.svg';
import siteLogoTatva from '../../assets/images/site-logo-tatva.svg';

// Constants
import { RESET_PASSWORD_API } from '../../constants/configs';
import { API_REQUEST, LABELS, ROUTES } from '../../constants/general';
import { getFormErrorMessage, isFormFieldValid, trimObjectValues } from '../../utils/general';
import { resetPasswordValidationSchema } from '../../constants/schemas';

// Components
import CustomPassword from '../../components/CustomPassword';

// Utility Functions
import API from '../../utils/api';
import { handleError, handleSuccess } from '../../utils/toast';

// Utility Packages
import { classNames } from 'primereact/utils';
import { Button } from 'primereact/button';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import { useParams } from 'react-router';
import { useSelector } from 'react-redux';
import { dispatch } from '../../utils/store';
import { Actions } from '../../redux/actions';

const ResetPassword = () => {
  const navigate = useNavigate();
  const { token } = useParams();

  // Store
  const toastReff = useSelector((state) => state.notifications.toastReff);

  const formik = useFormik({
    initialValues: {
      password: '',
      confirmPassword: '',
      token: token
    },

    validationSchema: resetPasswordValidationSchema,
    onSubmit: (values, { resetForm }) => {
      trimObjectValues(values);
      resetPassword(
        {
          newPassword: values.password
        },
        resetForm
      );
    }
  });

  const resetPassword = (password, resetForm) => {
    dispatch(Actions.User.SetLoading, true);
    API(API_REQUEST.post, `${RESET_PASSWORD_API}/${token}`, password)
      .then((res) => {
        handleSuccess(toastReff, res);
        resetForm({ values: '' });
        dispatch(Actions.User.SetLoading, false);
        navigate(ROUTES.LOGIN);
      })
      .catch((error) => {
        handleError(toastReff, error);
        dispatch(Actions.User.SetLoading, false);
        console.log(error);
      });
  };

  return (
    <div className="authentication-wrapper reset-pwd">
      <div className="authentication-inner">
        <div className="login-container">
          <div className="site-logo">
            <a href={ROUTES.HOME} title="Interview Management System">
              <img src={logoImg} alt="" onClick={() => navigate(ROUTES.HOME)} />
            </a>
          </div>
          <h2>{LABELS.RESET_PASSWORD}</h2>
          <form onSubmit={formik.handleSubmit} className="p-fluid">
            <div className="field">
              <CustomPassword
                htmlFor="password"
                text={LABELS.INPUT.NEW_PASSWORD}
                ismandatory
                isbold
                id="password"
                name="password"
                placeholder={LABELS.INPUT.NEW_PASSWORD}
                value={formik.values.password}
                onChange={formik.handleChange}
                toggleMask
                className={classNames({
                  'p-invalid': isFormFieldValid(formik, 'password')
                })}
                feedback={false}
              />
              {getFormErrorMessage(formik, 'password')}
            </div>
            <div className="field">
              <CustomPassword
                htmlFor="confirmPassword"
                text={LABELS.INPUT.CONFIRM_PASSWORD}
                ismandatory
                isbold
                id="confirmPassword"
                name="confirmPassword"
                placeholder={LABELS.INPUT.CONFIRM_PASSWORD}
                value={formik.values.confirmPassword}
                onChange={formik.handleChange}
                toggleMask
                className={classNames({
                  'p-invalid': isFormFieldValid(formik, 'confirmPassword')
                })}
                feedback={false}
              />
              {getFormErrorMessage(formik, 'confirmPassword')}
            </div>
            <Button className="w-full" onClick={formik.handleSubmit}>
              {LABELS.BUTTON.SAVE}
            </Button>
          </form>
          <div className="tatva-logo-wrap">
            <span>{LABELS.POWERED_BY}</span>
            <img src={siteLogoTatva} alt="Tatvasoft" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
