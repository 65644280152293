import { Accordion, AccordionTab } from 'primereact/accordion';
import { FAQs_DATA } from '../../constants/homePage';

function FAQs() {
  return (
    <div className='faq-page-main'>
      <div className='faq-wrapper'>
      <Accordion>
        {FAQs_DATA.map((faq, index) => (
          <AccordionTab
            key={index}
            header={
              <span>{faq.header}</span>
            }>
            <p>{faq.content}</p>
          </AccordionTab>
        ))}
      </Accordion>
      </div>
    </div>
  );
}

export default FAQs;
