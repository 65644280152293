import React from 'react';

// Constants
import { educationValidationSchema } from '../constants/schemas';
import { getFormErrorMessage, isFormFieldValid } from '../utils/general';
import { GRADE, LABELS } from '../constants/general';

// Components
import Label from './Label';

// Utility Functions
import { updateEducationDetails } from '../middleware/educationDetails';

// Utility Packages
import { classNames } from 'primereact/utils';
import { Sidebar } from 'primereact/sidebar';
import { Button } from 'primereact/button';
import { useSelector } from 'react-redux';
import { useFormik } from 'formik';
import { Editor } from 'primereact/editor';
import { InputText } from 'primereact/inputtext';
import DatePicker from 'react-datepicker';
import { Dropdown } from 'primereact/dropdown';

const AddEditEducation = (props) => {
  const { onHide, show, selectedEducation } = props;

  // Store
  const toastReff = useSelector((state) => state.notifications.toastReff);
  const { search, pageNumber, size } = useSelector((state) => state.educationDetails);

  const formik = useFormik({
    initialValues: {
      details: selectedEducation?.Details || '',
      course: selectedEducation?.Course || '',
      university: selectedEducation?.University || '',
      passingYear: selectedEducation?.PassingYear || '',
      grade: selectedEducation?.Grade
        ? GRADE.filter((grade) => grade.code === selectedEducation?.Grade)[0]
        : {},
      remarks: selectedEducation?.Remark || ''
    },
    validationSchema: educationValidationSchema,
    onSubmit: (values) => {
      updateEducationDetails(
        selectedEducation,
        { ...values, grade: values.grade?.code },
        search,
        pageNumber,
        size,
        onHide,
        toastReff
      );
    }
  });

  return (
    <>
      <Sidebar visible={show} onHide={onHide} position="right" className="sidebar-drawer">
        <div className="form-box-wrapper change-password-form">
          <div className="title-wrapper">
            <p className="card-title">Education</p>
            <button className="p-sidebar-close" onClick={onHide}>
              <span className="p-sidebar-close-icon pi pi-times" />
            </button>
          </div>
          <form onSubmit={formik.handleSubmit} className="p-fluid">
            <div className="form-row-wrapper form-row-wrap">
              <div className="form-col full-width">
                <div className="form-group-outer">
                  <div className="custom-form-group">
                    <Label
                      htmlFor="details"
                      text={LABELS.INPUT.EDUCATION_DETAILS}
                      isBold
                      isMandatory
                    />
                    <InputText
                      id="details"
                      name="details"
                      placeholder={LABELS.INPUT.EDUCATION_DETAILS}
                      value={formik.values.details}
                      onChange={formik.handleChange}
                      className={classNames({
                        'p-invalid': isFormFieldValid(formik, 'details')
                      })}
                    />
                  </div>
                  {getFormErrorMessage(formik, 'details')}
                </div>
              </div>

              <div className="form-col full-width">
                <div className="form-group-outer">
                  <div className="custom-form-group">
                    <Label htmlFor="course" text={LABELS.INPUT.COURSE} isBold isMandatory />
                    <InputText
                      id="course"
                      name="course"
                      placeholder={LABELS.INPUT.COURSE}
                      value={formik.values.course}
                      onChange={formik.handleChange}
                      className={classNames({
                        'p-invalid': isFormFieldValid(formik, 'course')
                      })}
                    />
                  </div>
                  {getFormErrorMessage(formik, 'course')}
                </div>
              </div>

              <div className="form-col full-width">
                <div className="form-group-outer">
                  <div className="custom-form-group">
                    <Label htmlFor="university" text={LABELS.INPUT.UNIVERSITY} isBold isMandatory />
                    <InputText
                      id="university"
                      name="university"
                      placeholder={LABELS.INPUT.UNIVERSITY}
                      value={formik.values.university}
                      onChange={formik.handleChange}
                      className={classNames({
                        'p-invalid': isFormFieldValid(formik, 'university')
                      })}
                    />
                  </div>
                  {getFormErrorMessage(formik, 'university')}
                </div>
              </div>
              <div className="form-col">
                <div className="form-group-outer">
                  <div className="custom-form-group">
                    <Label
                      htmlFor="passingYear"
                      text={LABELS.INPUT.PASSING_YEAR}
                      isBold
                      isMandatory
                    />
                    <DatePicker
                      id="passingYear"
                      name="passingYear"
                      dateFormat="yyyy"
                      placeholderText="Year"
                      showYearPicker
                      selected={formik.values.passingYear}
                      onChange={(date) => formik.setFieldValue('passingYear', date)}
                      maxDate={new Date()}
                      onKeyDown={(e) => {
                        e.preventDefault();
                      }}
                      className={classNames({
                        'p-invalid': isFormFieldValid(formik, 'passingYear')
                      })}
                    />
                  </div>
                  {getFormErrorMessage(formik, 'passingYear')}
                </div>
              </div>
              <div className="form-col">
                <div className="form-group-outer">
                  <div className="custom-form-group">
                    <Label htmlFor="grade" text={LABELS.INPUT.GRADE} isBold isMandatory />
                    <Dropdown
                      id="grade"
                      name="grade"
                      placeholder={LABELS.INPUT.GRADE}
                      value={formik.values.grade}
                      onChange={(e) => formik.setFieldValue('grade', e.value)}
                      options={GRADE}
                      optionLabel="name"
                      className={classNames({
                        'p-invalid': isFormFieldValid(formik, 'grade')
                      })}
                    />
                  </div>
                  {getFormErrorMessage(formik, 'grade')}
                </div>
              </div>
              <div className="form-col full-width">
                <div className="form-group-outer">
                  <div className="custom-form-group">
                    <Label htmlFor="remarks" text={LABELS.INPUT.REMARKS} isBold />
                    <Editor
                      id="remarks"
                      name="remarks"
                      value={formik.values.remarks}
                      onTextChange={(e) => formik.setFieldValue(`remarks`, e.htmlValue)}
                      style={{ height: '260px' }}
                      className={classNames({
                        'p-invalid': isFormFieldValid(formik, 'remarks')
                      })}
                    />
                  </div>
                  {getFormErrorMessage(formik, 'remarks')}
                </div>
              </div>
            </div>
            <div className="form-btn-wrapper">
              <Button
                type="button"
                variant="contained"
                className="ims-blue-btn"
                onClick={formik.handleSubmit}>
                {LABELS.BUTTON.SAVE}
              </Button>
              <Button
                variant="contained"
                onClick={onHide}
                color="error"
                className="gray-btn border-btn">
                {LABELS.BUTTON.CANCEL}
              </Button>
            </div>
          </form>
        </div>
      </Sidebar>
    </>
  );
};

export default AddEditEducation;
