import { useEffect, useState } from 'react';

// Components
import CustomDataTable from '../../components/DataTable';
import AddEditEmploymentDetails from '../../components/AddEditEmploymentDetails';

// Constants
import { DEBOUNCE_TIMEOUT, EMPLOYMENT_TYPE, EMPLOYMENTS_COLUMNS } from '../../constants/general';

// Utility Functions
import { deleteEmployment, getEmploymentDetailsData } from '../../middleware/employmentDetails';

// Utility Packages
import { useSelector } from 'react-redux';
import { ConfirmDialog } from 'primereact/confirmdialog';
import { dispatch } from '../../utils/store';
import { Actions } from '../../redux/actions';
import { debounce } from '../../utils/debounce';
import { Button } from 'primereact/button';
import { Tooltip as ReactTooltip } from 'react-tooltip';

// images
import editIcon from '../../assets/images/grid-edit-icon.svg';
import deleteIcon from '../../assets/images/grid-delete-icon.svg';

function EmploymentDetails() {
  // Store
  const toastReff = useSelector((state) => state.notifications.toastReff);
  const { employments, totalRecords, search, pageNumber, size } = useSelector(
    (state) => state.employmentDetails
  );

  useEffect(
    () => getEmploymentDetailsData(search, pageNumber, size, toastReff),
    [search, pageNumber, size]
  );

  // State
  const [employmentDetailsModal, setEmploymentDetailsModal] = useState(false);
  const [employmentsDetails, setEmploymentDetails] = useState([]);
  const [selectedEmployment, setSelectedEmployment] = useState(null);
  const [deleteEmploymentModal, setDeleteEmploymentModal] = useState(false);

  const onEditClick = (employment) => {
    setSelectedEmployment(employment);
    setEmploymentDetailsModal(true);
  };

  const onDeleteClick = (employment) => {
    setSelectedEmployment(employment);
    setDeleteEmploymentModal(true);
  };

  useEffect(() => {
    return () => {
      dispatch(Actions.Employments.EmploymentsReset);
    };
  }, []);

  useEffect(() => {
    const modifiedRecords = employments?.map((education) => {
      return {
        ...education,
        CurrentEmployment:
          education?.CurrentEmployment === '1'
            ? 'Yes'
            : education?.CurrentEmployment === '2'
              ? 'No'
              : '--',
        EmploymentType: education?.EmploymentType
          ? EMPLOYMENT_TYPE.filter((type) => type?.code === education?.EmploymentType)[0].name
          : '--',
        TotalExperience: `${education?.TotalExperience} Years` || '--',
        JoiningSalary: `${education?.JoiningSalary} LPA` || '--',
        LastSalary: `${education?.LastSalary} LPA` || '--',
        action: (
          <div className="table-actions-wrapper">
            <Button
              data-tooltip-id="edit-button"
              data-tooltip-content="Edit"
              // icon="pi pi-pencil"
              rounded
              text
              severity="secondary"
              aria-label="Edit"
              onClick={() => onEditClick(education)}
              className="grid-action-btn">
              <em>
                <img src={editIcon} alt="edit icon" />
              </em>
            </Button>
            <Button
              data-tooltip-id="delete-button"
              data-tooltip-content="Delete"
              // icon="pi pi-trash"
              rounded
              text
              // severity="danger"
              aria-label="Delete"
              onClick={() => onDeleteClick(education)}
              className="grid-action-btn">
              <em>
                <img src={deleteIcon} alt="edit icon" />
              </em>
            </Button>
            <ReactTooltip id="edit-button" place="top" />
            <ReactTooltip id="delete-button" place="top" />
          </div>
        )
      };
    });
    setEmploymentDetails(modifiedRecords);
  }, [employments]);

  const handlePagination = (pageNumber, size) => {
    dispatch(Actions.Employments.SetPageNumber, pageNumber);
    dispatch(Actions.Employments.SetPageSize, size);
  };

  const handleSearch = (searchValue) => {
    dispatch(Actions.Employments.SetSearch, searchValue);
  };

  return (
    <div className="employement-details-wrapper">
      <ConfirmDialog
        header="Delete"
        message="Are you sure you want to delete?"
        icon="pi pi-exclamation-triangle"
        visible={deleteEmploymentModal}
        onHide={() => {
          setSelectedEmployment(null);
          setDeleteEmploymentModal(false);
        }}
        accept={() =>
          deleteEmployment(
            selectedEmployment?.ID,
            search,
            pageNumber,
            size,
            toastReff,
            setDeleteEmploymentModal
          )
        }
      />
      <CustomDataTable
        data={employmentsDetails}
        onSearch={debounce(handleSearch, DEBOUNCE_TIMEOUT)}
        onAdd={() => setEmploymentDetailsModal(true)}
        totalRecords={totalRecords}
        addButtonTooltip="Add Employment"
        columns={EMPLOYMENTS_COLUMNS}
        onPagination={handlePagination}
        // onSort={handleSort}
      />

      {employmentDetailsModal && (
        <AddEditEmploymentDetails
          show={employmentDetailsModal}
          onHide={() => {
            setEmploymentDetailsModal(false);
            setSelectedEmployment(null);
          }}
          selectedEmployment={selectedEmployment}
        />
      )}
    </div>
  );
}

export default EmploymentDetails;
