// Constants
import {
  BANNER_HEADING,
  CAREER_DESCRIPTION,
  CTA_EMAIL,
  CTA_EMAIL_TITLE,
  WARNING_MESSAGE
} from '../constants/homePage';
import { API_REQUEST } from '../constants/general';
import { JOB_API } from '../constants/configs';

// Images
import bannerMobileJpg from '../assets/images/banner-career-mob.jpg';
import bannerMobileWebp from '../assets/images/banner-career-mob.webp';
import bannerJpg from '../assets/images/banner-career.jpg';
import bannerWebp from '../assets/images/banner-career.webp';
import warningIcon from '../assets/images/warning-image.svg';

// Utility Packages
import { AutoComplete } from 'primereact/autocomplete';
import { useState } from 'react';
import API from '../utils/api';
import { useNavigate } from 'react-router-dom';

function BannerDescription() {
  const navigate = useNavigate();
  const [searchText, setSearchText] = useState('');
  const [jobs, setJobs] = useState([]);

  const candidateTemplate = (option) => {
    return (
      <div id={option.ID}>
        <span>{option?.JobTitle || ''}</span>
      </div>
    );
  };

  const modifiedOptions = (options) =>
    options?.map((opt) => {
      return {
        ...opt,
        name: `${opt.JobTitle}`,
        code: `${opt.ID}`
      };
    });

  const handleSearch = () => {
    if (searchText) {
      const sanitizeString = searchText.trim().toString();

      const params = {
        ...(!!sanitizeString ? { searchName: sanitizeString } : {})
      };

      API(API_REQUEST.get, JOB_API, { params })
        .then((res) => {
          setJobs(modifiedOptions(res?.data?.data?.data || []));
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  return (
    <>
      <section className="page-banner-section">
        <picture className="tatvasoft-inner-banner">
          <source media="(min-width: 768px)" srcSet={`${bannerWebp}, ${bannerJpg}`} />
          <source srcSet={`${bannerMobileWebp}, ${bannerMobileJpg}`} />
          <img src={bannerMobileJpg} alt="Banner Preview" />
        </picture>
        <div className="banner-content-wrapper">
          <div className="container">
            <div className="banner-content">
              <h1>{BANNER_HEADING}</h1>
              {/* <p>{BANNER_DESCRIPTION}</p> */}
              <div className="form-wrapper">
                <form action="">
                  <AutoComplete
                    name="Search jobs"
                    className="has-searchbar"
                    id="searchjobs"
                    value={searchText}
                    suggestions={jobs}
                    completeMethod={handleSearch}
                    delay={1000}
                    onChange={(e) => {
                      if (e.target.value) {
                        setSearchText(e.target.value);
                        if (e.target.value?.code) {
                          navigate(`job/${e.target.value?.code}`);
                        }
                      }
                    }}
                    itemTemplate={candidateTemplate}
                    placeholder="Search by job title or keyword"
                  />
                  {/* <InputText type="search" /> */}
                  {/* <Button type="submit">find jobs</Button> */}
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="warning-msg-block">
        <div className="container">
          <div className="warning-content-wrapper">
            <img src={warningIcon} alt="Warning message" />
            <p>
              {WARNING_MESSAGE}
              <a href={`mailto:${CTA_EMAIL}`} title={CTA_EMAIL_TITLE}>
                {CTA_EMAIL}
              </a>
              .
            </p>
          </div>
        </div>
      </div>

      <div className="tatva-career-description">
        <div className="container">
          <p>{CAREER_DESCRIPTION}</p>
        </div>
      </div>
    </>
  );
}

export default BannerDescription;
