import { useEffect, useState } from 'react';

//Components
import CustomDataTable from '../../components/DataTable';
import ApplyJob from '../../components/ApplyJob';

//Constants
import { DEBOUNCE_TIMEOUT, JOB_COLUMNS } from '../../constants/general';

//Utility Functions
import { debounce } from '../../utils/debounce';
import { dispatch } from '../../utils/store';
import { getJobDetailsData } from '../../middleware/jobDetails';

//Utility Packages
import { Tooltip as ReactTooltip } from 'react-tooltip';
import { useSelector } from 'react-redux';
import { Actions } from '../../redux/actions';
import { Button } from 'primereact/button';
import { Chip } from 'primereact/chip';

function Jobs() {
  // Store
  const { jobs, totalRecords, search, pageNumber, size, sortBy, orderBy } = useSelector(
    (state) => state.jobs
  );
  const toastRef = useSelector((state) => state.notifications.toastReff);

  // State
  const [jobDetails, setJobDetails] = useState([]);
  const [selectedJobId, setSelectedJobId] = useState(null);
  const [visibleDialog, setVisibleDialog] = useState(false);

  useEffect(() => {
    getJobDetailsData(search, pageNumber, size, sortBy, orderBy, toastRef);
  }, [search, pageNumber, size, sortBy, orderBy, toastRef]);
  useEffect(() => {
    return () => {
      dispatch(Actions.Jobs.JobsReset);
    };
  }, []);

  useEffect(() => {
    if (jobs?.length) {
      const modifiedRecords = jobs.map((job) => ({
        ...job,
        Skills: job?.Skills?.length && (
          <div className="technology-chip-list flex flex-wrap gap-2">
            {job.Skills.map((skill) => (
              <Chip key={skill} label={skill} className="technology-chip" />
            ))}
          </div>
        ),
        Experience: `${job?.Experience} Y`,
        action: (
          <div className="table-actions-wrapper">
            <Button
              data-tooltip-id="apply-job-button"
              data-tooltip-content="Apply Job"
              icon="pi pi-file-check"
              rounded
              text
              severity="secondary"
              aria-label="ApplyJob"
              onClick={() => onApplyJobClick(job.ID)}
              className="grid-action-btn"
            />
            <ReactTooltip id="apply-job-button" place="top" />
          </div>
        )
      }));
      setJobDetails(modifiedRecords);
    } else {
      setJobDetails([]);
    }
  }, [jobs]);

  const onApplyJobClick = (ID) => {
    setSelectedJobId(ID);
    setVisibleDialog(true);
  };

  const onHideDialog = () => {
    setVisibleDialog(false);
    setSelectedJobId(null);
  };

  const handlePagination = (pageNumber, size) => {
    dispatch(Actions.Jobs.SetPageNumber, pageNumber);
    dispatch(Actions.Jobs.SetPageSize, size);
  };

  const handleSearch = (searchValue) => {
    dispatch(Actions.Jobs.SetSearch, searchValue);
  };

  const handleSort = (field) => {
    let sortOrder = 'ASC';
    if (sortBy === field) sortOrder = orderBy === 'ASC' ? 'DESC' : 'ASC';
    dispatch(Actions.Jobs.SetSortBy, field);
    dispatch(Actions.Jobs.SetOrderBy, sortOrder);
  };

  return (
    <div className="job-table-wrapper">
      <CustomDataTable
        data={jobDetails}
        onSearch={debounce(handleSearch, DEBOUNCE_TIMEOUT)}
        totalRecords={totalRecords}
        columns={JOB_COLUMNS}
        onPagination={handlePagination}
        onSort={handleSort}
      />
      <ApplyJob selectedJobId={selectedJobId} visible={visibleDialog} onHide={onHideDialog} />
    </div>
  );
}

export default Jobs;
