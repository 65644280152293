import { useEffect, useRef, useState } from 'react';

// Components
import Label from '../../components/Label';
import ProfileUpdatePassword from '../../components/ProfileUpdatePassword';
import ProjectDetails from './ProjectDetails';
import EducationDetails from './EducationDetails';
import EmploymentDetails from './EmploymentDetails';
import ResumeDetails from './ResumeDetails';

// Constants
import {
  API_REQUEST,
  CANDIDATE_LOCATIONS,
  LABELS,
  MessageType,
  PROFILE_FORMAT_ERROR,
  PROFILE_SIZE_ERROR,
  ROUTES,
  Tab_INDEX
} from '../../constants/general';
import { PROFILE_API, PROFILE_IMAGE_API } from '../../constants/configs';
import { profileValidationSchema } from '../../constants/schemas';

// Images
import AvtarImage from '../../components/AvtarImage';
import editIcon from '../../assets/images/grid-edit-icon.svg';
import deleteIcon from '../../assets/images/grid-delete-icon.svg';

// Utility Functions
import { getLocalStorageUserData, setLocalStorageUserData } from '../../utils/session';
import { getFormErrorMessage, isFormFieldValid } from '../../utils/general';
import API from '../../utils/api';
import { handleError, handleSuccess, showMessage } from '../../utils/toast';
import { dispatch } from '../../utils/store';
import { Actions } from '../../redux/actions';

// Utility Packages
import { useFormik } from 'formik';
import { useSelector } from 'react-redux';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import classNames from 'classnames';
import { RadioButton } from 'primereact/radiobutton';
import { useNavigate } from 'react-router-dom';
import { InputTextarea } from 'primereact/inputtextarea';
import { confirmDialog } from 'primereact/confirmdialog';
import DatePicker from 'react-datepicker';
import { InputNumber } from 'primereact/inputnumber';
import CreatableSelect from 'react-select/creatable';
import { TabView, TabPanel } from 'primereact/tabview';

import { ConfirmDialog } from 'primereact/confirmdialog';

const profileImagePath = `${process.env.REACT_APP_PROFILE_IMAGE_URL}/public/uploads/profile`;

function Profile() {
  const navigate = useNavigate();

  // State
  const [imagePrev, setImagePrev] = useState(null);
  const [userData, setUserData] = useState({
    firstName: '',
    middleName: '',
    lastName: '',
    email: '',
    phone: '',
    secondPhone: '',
    gender: '',
    address: '',
    currentLocation: '',
    location: '',
    dateOfBirth: '',
    maritalStatus: '',
    language: '',
    // technology: [],
    profileImage: '',
    // resumeFilePath: '',
    resumeName: '',
    keySkill: '',
    softSkill: ''
  });
  const [removeImage, setRemoveImage] = useState(false);
  const [showChangePasswordPopup, setShowChangePasswordPopup] = useState(false);
  const [languageInput, setLanguageInput] = useState('');
  const [keySkillInput, setKeySkillInput] = useState('');
  const [softSkillInput, setSoftSkillInput] = useState('');
  const [languageSuggestion, setLanguageSuggestion] = useState([]);
  const [keySkillSuggestion, setKeySkillSuggestion] = useState([]);
  const [softSkillSuggestion, setSoftSkillSuggestion] = useState([]);

  // Store
  const toastReff = useSelector((state) => state.notifications.toastReff);

  // React useRef hook
  const fileRef = useRef(null);

  // State
  const [tabIndex, setTabIndex] = useState(0);

  const { firstName, lastName, id } = getLocalStorageUserData();

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: userData,
    validationSchema: profileValidationSchema,
    onSubmit: (values) => {
      updateProfileData({
        ...values,
        currentLocation:
          values.currentLocation === 'other'
            ? values.location?.trim()
            : values.currentLocation?.trim()
      });
    }
  });

  const userName = `${firstName || ''} ${lastName || ''}`;

  useEffect(() => {
    if (tabIndex === Tab_INDEX['Personal Details']) {
      getProfileData();
    }
  }, [tabIndex]);

  const getProfileData = () => {
    dispatch(Actions.User.SetLoading, true);
    API(API_REQUEST.get, PROFILE_API)
      .then((res) => {
        handleSuccess(toastReff, res);
        dispatch(Actions.User.SetLoading, false);
        const { UserProfile } = res?.data?.data?.data;

        const storageData = getLocalStorageUserData();
        setLocalStorageUserData({ ...storageData, userProfile: UserProfile });
        updatedData(res?.data?.data?.data);
        // setIsFile(ResumeOfCandidate?.FilePath || '');
        // setFileName(
        //   ResumeOfCandidate?.FilePath
        //     ? ResumeOfCandidate?.FilePath?.split('-').slice(2).join('')
        //     : ''
        // );
      })
      .catch((error) => {
        dispatch(Actions.User.SetLoading, false);
        handleError(toastReff, error);
        console.log(error);
      });
  };

  const updatedData = (data) => {
    let isLocationFromDropdown = CANDIDATE_LOCATIONS.some(
      (location) => location.code === data?.Location
    );
    setUserData((preState) => ({
      ...preState,
      firstName: data?.FirstName || '',
      profileImage: data?.UserProfile || '',
      lastName: data?.LastName || '',
      phone: data?.Mobile || '',
      email: data?.Email || '',
      gender: data?.Gender || '',
      address: data?.Address || '',
      middleName: data?.MiddleName || '',
      secondPhone: data?.SecondaryMobile || '',
      currentLocation: data?.Location ? (isLocationFromDropdown ? data.Location : 'other') : '',
      location: data?.Location ? (!isLocationFromDropdown ? data.Location : '') : '',
      dateOfBirth: data?.Dob || '',
      maritalStatus: data?.MaritalStatus || '',
      language: data?.Language
        ? data?.Language?.split(',').map((item) => ({
            label: item.trim(),
            value: item.trim()
          }))
        : '',
      resumeName: data?.ResumeName || '',
      keySkill: data?.KeySkill
        ? data?.KeySkill?.split(',').map((item) => ({
            label: item.trim(),
            value: item.trim()
          }))
        : '',
      softSkill: data?.SoftSkill
        ? data?.SoftSkill?.split(',').map((item) => ({
            label: item.trim(),
            value: item.trim()
          }))
        : ''
    }));
  };

  // const getTechnology = () => {
  //   dispatch(Actions.User.SetLoading, true);
  //   API(API_REQUEST.get, TECHNOLOGY_API)
  //     .then((res) => {
  //       dispatch(Actions.User.SetLoading, false);
  //       setTechnologies(res?.data?.data?.data || []);
  //     })
  //     .catch((error) => {
  //       dispatch(Actions.User.SetLoading, false);
  //       console.log(error);
  //     });
  // };

  const updateProfileData = (data) => {
    dispatch(Actions.User.SetLoading, true);

    const payload = new FormData();
    Object.keys(data).forEach((key) => {
      const value = data[key];

      // Skip fields with empty strings, null, or undefined values
      if (value === null || value === undefined) {
        return '';
      }

      if (key === 'profileImage' && imagePrev) {
        payload.append('profileImage', value);
        // } else if (key === 'resumeFilePath' && fileName) {
        //   payload.append('resumeFilePath', value);
      } else if (Array.isArray(value)) {
        payload.append(key, value.map((t) => t.value).toString());
        // value.forEach((tech) => payload.append(`${key}[]`, JSON.stringify(tech?.value)));
      } else {
        payload.append(key, value);
      }
    });

    API(API_REQUEST.put, PROFILE_API, payload)
      .then((res) => {
        handleSuccess(toastReff, res);
        formik.setFieldValue('profileImage', '');
        // formik.setFieldValue('resumeFilePath', '');
        setImagePrev('');
        // setFileName('');
        getProfileData();
      })
      .catch((error) => {
        dispatch(Actions.User.SetLoading, false);
        handleError(toastReff, error);
        console.log(error);
      });
  };

  const imageName = userData?.profileImage || '';
  const encodedPath = imageName
    .split('/')
    .map((p) => encodeURIComponent(p))
    .join('/');

  useEffect(() => {
    if (removeImage === true) {
      formik.setFieldValue('profileImage', '');
      setImagePrev(null);
    }
  }, [removeImage]);

  const removeImageConfirmation = () => {
    confirmDialog({
      header: 'Delete',
      message: 'Are you sure you want to delete profile image?',
      icon: 'pi pi-exclamation-triangle',
      accept: () => deleteImage()
    });
  };

  const deleteImage = () => {
    dispatch(Actions.User.SetLoading, true);
    API(API_REQUEST.delete, PROFILE_IMAGE_API)
      .then((res) => {
        handleSuccess(toastReff, res);
        dispatch(Actions.User.SetLoading, false);
        const storageData = getLocalStorageUserData();
        setLocalStorageUserData({ ...storageData, userProfile: null });
        setRemoveImage(true);
      })
      .catch((error) => {
        handleError(toastReff, error);
        dispatch(Actions.User.SetLoading, false);
        console.log(error);
      });
    setUserData((prevState) => ({
      ...prevState,
      profileImage: null
    }));
  };

  const handleLanguageSuggestion = (e) => {
    const options = languageSuggestion.concat(e);
    const newOptions = options.filter(
      (item, index) => options.findIndex((obj) => obj.value === item.value) === index
    );
    setLanguageSuggestion(newOptions);
  };

  const handleKeySkillSuggestion = (e) => {
    const options = keySkillSuggestion.concat(e);
    const newOptions = options.filter(
      (item, index) => options.findIndex((obj) => obj.value === item.value) === index
    );
    setKeySkillSuggestion(newOptions);
  };

  const handleSoftSkillSuggestion = (e) => {
    const options = softSkillSuggestion.concat(e);
    const newOptions = options.filter(
      (item, index) => options.findIndex((obj) => obj.value === item.value) === index
    );
    setSoftSkillSuggestion(newOptions);
  };

  return (
    <>
      <div className="user-profile-wrapper">
        <ConfirmDialog />
        <TabView
          activeIndex={tabIndex}
          onTabChange={(e) => setTabIndex(e.index)}
          className="custom-tabbing">
          <TabPanel header="Personal Details">
            <form
              onSubmit={formik.handleSubmit}
              className="form-main"
              encType="multipart/form-data">
              <div className="user-profile-inner-wrapper">
                <div className="left-col">
                  <div className="form-col">
                    <div className="profile-box">
                      <div className="box-inner">
                        <div className="profile-img">
                          {userData?.profileImage && !imagePrev && !removeImage ? (
                            <img
                              src={`${profileImagePath}/${id}/${encodedPath}`}
                              alt="profile"
                              className="user-img"
                            />
                          ) : !imagePrev || removeImage ? (
                            <AvtarImage
                              className="user-img"
                              firstName={firstName}
                              lastName={lastName}
                            />
                          ) : (
                            <img src={imagePrev} alt="profile" className="user-img" />
                          )}
                          <input
                            hidden
                            ref={fileRef}
                            type="file"
                            id="profileImage"
                            name="profileImage"
                            onChange={(e) => {
                              if (e.target.files[0]) {
                                if (
                                  ['image/png', 'image/jpeg', 'image/jpg'].includes(
                                    e.target.files[0].type
                                  ) &&
                                  e.target.files[0].size < 1024 * 1024
                                ) {
                                  setImagePrev(URL.createObjectURL(e.target.files[0]));
                                  formik.setFieldValue('profileImage', e.target.files[0]);
                                } else {
                                  if (
                                    !['image/png', 'image/jpeg', 'image/jpg'].includes(
                                      e.target.files[0].type
                                    )
                                  )
                                    showMessage(toastReff, PROFILE_FORMAT_ERROR, MessageType.Error);
                                  else if (e.target.files[0].size >= 1024 * 1024)
                                    showMessage(toastReff, PROFILE_SIZE_ERROR, MessageType.Error);

                                  setImagePrev(false);
                                }
                                fileRef.current.value = null;
                                setRemoveImage(false);
                              }
                            }}
                            accept="image/png, image/jpeg ,image/jpg"
                            className={classNames({
                              'p-invalid': isFormFieldValid(formik, 'profileImage')
                            })}
                          />
                        </div>
                        <div className="profile-detail-block">
                          <div className="user-details">
                            <div className="user-name">{userName}</div>
                          </div>
                          <div className="edit-delete-btn">
                            <Button
                              type="button"
                              className="border-none edit-btn"
                              // icon="pi pi-pencil"
                              label={!userData?.profileImage ? 'Upload' : 'Change'}
                              onClick={() => {
                                fileRef.current.click();
                              }}>
                              <em>
                                <img src={editIcon} alt="edit icon" />
                              </em>
                            </Button>
                            <Button
                              disabled={!userData?.profileImage ? 'disabled' : ''}
                              className="border-none delete-btn"
                              // icon="pi pi-trash"
                              label="Delete"
                              type="button"
                              onClick={() => removeImageConfirmation()}
                              text>
                              <em>
                                <img src={deleteIcon} alt="delete icon" />
                              </em>
                            </Button>
                          </div>
                          <div className="notification-outer">
                            <div className="notification-div reset-password-div">
                              <div
                                className="font-medium no-underline text-blue-500 cursor-pointer primary-link"
                                onClick={() => setShowChangePasswordPopup(true)}>
                                Change Password
                              </div>
                            </div>
                          </div>
                        </div>
                        {
                          <small className="p-error">
                            {formik.errors['profileImage'] && formik.errors['profileImage']}
                          </small>
                        }
                      </div>
                    </div>
                  </div>
                  {/* <div className="form-col resume-col">
                    <div className="profile-box">
                      <div className="box-inner">
                        <div className="title-wrapper">
                          <h3 style={{ textAlign: 'center' }}>Resume</h3>
                          <div className="edit-delete-btn">
                            <Button
                              type="button"
                              className="border-none edit-btn"
                              icon="pi pi-eye"
                              label="Preview"
                              onClick={() => {
                                if (formik.values.resumeFilePath || isFile) {
                                  let fileUrl = '';
                                  if (formik.values.resumeFilePath) {
                                    fileUrl = URL.createObjectURL(formik.values.resumeFilePath);
                                  } else {
                                    fileUrl = `${resumePath}/${id}/${isFile}`;
                                  }
                                  openResumeLink(fileUrl);
                                }
                              }}
                            />
                            <Button
                              disabled={!fileName ? 'disabled' : ''}
                              className="border-none edit-btn"
                              icon="pi pi-pencil"
                              label="Rename"
                              type="button"
                              onClick={() => {
                                setFileExtension(fileName.slice(-4));
                                formik.setFieldValue('resumeName', fileName.slice(0, -4));
                                setIsFileRename(true);
                              }}
                            />
                            <Button
                              disabled={!formik.values.resumeFilePath ? 'disabled' : ''}
                              className="border-none delete-btn"
                              icon="pi pi-trash"
                              label="Delete"
                              type="button"
                              onClick={() => {
                                formik.setFieldValue('resumeFilePath', '');
                                setFileName(isFile ? isFile?.split('-').slice(2).join('') : '');
                                resumeFileRef.current.value = null;
                              }}
                            />
                          </div>
                        </div>
                        <div className="profile-img">
                          <input
                            hidden
                            ref={resumeFileRef}
                            type="file"
                            id="resumeFilePath"
                            name="resumeFilePath"
                            onChange={(e) => {
                              formik.setFieldValue('resumeFilePath', e.target.files[0]);
                              setFileName(e.target.files[0].name);
                            }}
                            accept=".doc, .docx, .pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                            className={classNames({
                              'p-invalid': isFormFieldValid(formik, 'resumeFilePath')
                            })}
                          />
                          <div className="user-details">
                            {isFilRemane ? (
                              <InputText
                                id="resumeName"
                                name="resumeName"
                                placeholder={LABELS.INPUT.RESUME_NAME}
                                value={formik.values.resumeName}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                className={classNames({
                                  'p-invalid': isFormFieldValid(formik, 'resumeName')
                                })}
                              />
                            ) : (
                              <div className="user-name">{fileName || 'Not Available'}</div>
                            )}
                            {isFilRemane && (
                              <Button
                                type="button"
                                className="border-none edit-btn border-btn"
                                label={'Save Name'}
                                onClick={() => {
                                  if (fileExtension && formik.values.resumeName) {
                                    setFileName(formik.values.resumeName.concat(fileExtension));
                                  }
                                  setIsFileRename(false);
                                }}
                              />
                            )}
                          </div>
                        </div>
                        <div className="upload-resume">
                          <Button
                            type="button"
                            className="border-none edit-btn border-btn"
                            icon="pi pi-upload"
                            label={
                              !formik.values?.resumeFilePath ? 'Upload Your Resume Now' : 'Change'
                            }
                            onClick={() => {
                              resumeFileRef.current.click();
                            }}
                          />
                        </div>
                        {
                          <small className="p-error">
                            {formik.errors['resumeFilePath'] && formik.errors['resumeFilePath']}
                          </small>
                        }
                      </div>
                    </div>
                  </div> */}
                </div>
                <div className="profile-info-wrapper">
                  <div className="form-row-wrapper">
                    <div className="form-col form-col-3">
                      <div className="form-group-outer">
                        <div className="custom-form-group">
                          <Label htmlFor="firstName" text={LABELS.INPUT.FIRSTNAME} isBold />
                          <InputText
                            id="firstName"
                            name="firstName"
                            placeholder={LABELS.INPUT.FIRSTNAME}
                            value={formik.values.firstName}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            className={classNames({
                              'p-invalid': isFormFieldValid(formik, 'firstName')
                            })}
                          />
                        </div>
                        {getFormErrorMessage(formik, 'firstName')}
                      </div>
                    </div>
                    <div className="form-col form-col-3">
                      <div className="form-group-outer">
                        <div className="custom-form-group">
                          <Label htmlFor="middleName" text={LABELS.INPUT.MIDDLENAME} isBold />
                          <InputText
                            id="middleName"
                            name="middleName"
                            placeholder={LABELS.INPUT.MIDDLENAME}
                            value={formik.values.middleName}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            className={classNames({
                              'p-invalid': isFormFieldValid(formik, 'middleName')
                            })}
                          />
                        </div>
                        {getFormErrorMessage(formik, 'middleName')}
                      </div>
                    </div>
                    <div className="form-col form-col-3">
                      <div className="form-group-outer">
                        <div className="custom-form-group">
                          <Label htmlFor="lastName" text={LABELS.INPUT.LASTNAME} isBold />
                          <InputText
                            id="lastName"
                            name="lastName"
                            placeholder={LABELS.INPUT.LASTNAME}
                            value={formik.values.lastName}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            className={classNames({
                              'p-invalid': isFormFieldValid(formik, 'lastName')
                            })}
                          />
                        </div>
                        {getFormErrorMessage(formik, 'lastName')}
                      </div>
                    </div>
                    <div className="form-col form-col-3">
                      <div className="form-group-outer">
                        <div className="custom-form-group">
                          <Label htmlFor="email" text={LABELS.INPUT.EMAIL} isBold />
                          <InputText
                            id="email"
                            name="email"
                            placeholder={LABELS.INPUT.EMAIL}
                            value={formik.values.email}
                            disabled
                            onChange={formik.handleChange}
                            className={classNames({
                              'p-invalid': isFormFieldValid(formik, 'email')
                            })}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="form-col form-col-3">
                      <div className="form-group-outer">
                        <div className="custom-form-group">
                          <Label htmlFor="phone" text={LABELS.INPUT.PHONE} isBold isMandatory />
                          <InputNumber
                            id="phone"
                            name="phone"
                            placeholder={LABELS.INPUT.PHONE}
                            value={formik.values.phone}
                            className={classNames({
                              'p-invalid': isFormFieldValid(formik, 'phone')
                            })}
                            onValueChange={(e) => formik.setFieldValue('phone', e.value)}
                            mode="decimal"
                            useGrouping={false}
                            maxLength={10}
                          />
                        </div>
                        {getFormErrorMessage(formik, 'phone')}
                      </div>
                    </div>
                    <div className="form-col form-col-3">
                      <div className="form-group-outer">
                        <div className="custom-form-group">
                          <Label htmlFor="secondPhone" text={LABELS.INPUT.SECOND_PHONE} isBold />
                          <InputNumber
                            id="secondPhone"
                            name="secondPhone"
                            placeholder={LABELS.INPUT.PHONE}
                            value={formik.values.secondPhone}
                            className={classNames({
                              'p-invalid': isFormFieldValid(formik, 'secondPhone')
                            })}
                            onValueChange={(e) => {
                              const value =
                                e.value === null || e.value === undefined ? '' : e.value;
                              formik.setFieldValue('secondPhone', value);
                            }}
                            mode="decimal"
                            useGrouping={false}
                            maxLength={10}
                          />
                        </div>
                        {getFormErrorMessage(formik, 'secondPhone')}
                      </div>
                    </div>

                    {/* <div className="form-col has-full-width">
                      <div className="form-group-outer">
                        <div className="custom-form-group">
                          <Label htmlFor="technology" text={LABELS.INPUT.TECHNOLOGY} isBold />
                          <MultiSelect
                            filter
                            name="technology"
                            id="technology"
                            optionLabel="Technology"
                            optionValue="ID"
                            value={formik.values.technology}
                            forceSelection
                            options={technologies}
                            onChange={formik.handleChange}
                            display="chip"
                            onBlur={formik.handleBlur}
                            placeholder="Select Technology *"
                            className={classNames({
                              'p-invalid': isFormFieldValid(formik, 'technology'),
                              'w-full': true
                            })}
                            panelClassName="technology-overlay"
                          />
                        </div>
                        {getFormErrorMessage(formik, 'technology')}
                      </div>
                    </div> */}
                    <div className="form-col form-col-3">
                      <div className="form-group-outer">
                        <div className="custom-form-group">
                          <Label
                            htmlFor="currentLocation"
                            text={LABELS.INPUT.CURRENT_LOCATION}
                            isMandatory
                          />
                          <Dropdown
                            id="currentLocation"
                            name="currentLocation"
                            value={formik.values.currentLocation}
                            optionLabel="name"
                            optionValue="code"
                            options={CANDIDATE_LOCATIONS}
                            onChange={formik.handleChange}
                            panelClassName="custom-dropdown-panel"
                            className={classNames({
                              'p-invalid': isFormFieldValid(formik, 'currentLocation'),
                              'w-full': true
                            })}
                          />
                        </div>
                        {getFormErrorMessage(formik, 'currentLocation') ||
                          getFormErrorMessage(formik, 'location')}
                      </div>
                    </div>
                    {formik.values.currentLocation === 'other' && (
                      <div className="form-col form-col-3">
                        <div className="form-group-outer">
                          <div className="custom-form-group">
                            <Label />
                            <InputText
                              id="location"
                              name="location"
                              value={formik.values.location}
                              placeholder={LABELS.INPUT.CURRENT_LOCATION}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              className={classNames('candidate-location-other', {
                                'p-invalid': isFormFieldValid(formik, 'location'),
                                'w-full': true
                              })}
                            />
                          </div>
                        </div>
                      </div>
                    )}
                    <div className="form-col form-col-3">
                      <div className="form-group-outer">
                        <div className="custom-form-group date-picker-wrapper">
                          <Label htmlFor="dateOfBirth" text={LABELS.INPUT.DOB} isBold isMandatory />
                          <DatePicker
                            id="dateOfBirth"
                            name="dateOfBirth"
                            dateFormat="dd/MM/yyyy"
                            placeholderText="Date"
                            peekNextMonth
                            showMonthDropdown
                            showYearDropdown
                            dropdownMode="select"
                            selected={formik.values.dateOfBirth}
                            onChange={(date) => formik.setFieldValue('dateOfBirth', date)}
                            maxDate={new Date().setFullYear(new Date().getFullYear() - 18)}
                            className={classNames({
                              'p-invalid': isFormFieldValid(formik, 'dateOfBirth')
                            })}
                          />
                        </div>
                        {getFormErrorMessage(formik, 'dateOfBirth')}
                      </div>
                    </div>
                    <div className="form-col has-full-width">
                      <div className="form-group-outer">
                        <div className="custom-form-group autocomplete">
                          <Label htmlFor="language" text={LABELS.INPUT.LANGUAGE} isBold />
                          <CreatableSelect
                            isMulti
                            hideSelectedOptions={false}
                            id="language"
                            name="language"
                            value={formik.values.language}
                            onBlur={formik.handleBlur}
                            onChange={(e) => {
                              formik.setFieldValue('language', e);
                              handleLanguageSuggestion(e);
                            }}
                            options={languageSuggestion}
                            placeholder={LABELS.INPUT.LANGUAGE}
                            className={classNames({
                              'p-invalid': isFormFieldValid(formik, 'language')
                            })}
                            classNamePrefix="custom-create-select"
                            inputValue={languageInput}
                            onKeyDown={(e) => {
                              if (e.key === 'Enter') {
                                e.preventDefault();
                                e.stopPropagation();
                                if (e.target.value) {
                                  const newOption = {
                                    label: e.target.value,
                                    value: e.target.value
                                  };
                                  formik.setFieldValue('language', [
                                    ...formik.values.language,
                                    newOption
                                  ]);
                                  handleLanguageSuggestion([newOption]);
                                  setLanguageInput('');
                                }
                              }
                            }}
                            onInputChange={(inputValue) => {
                              setLanguageInput(inputValue);
                            }}
                          />
                        </div>
                        {getFormErrorMessage(formik, 'language')}
                      </div>
                    </div>
                    <div className="form-col has-full-width">
                      <div className="form-group-outer">
                        <div className="custom-form-group autocomplete">
                          <Label htmlFor="keySkill" text={LABELS.INPUT.KEY_SKILLS} isBold />
                          <CreatableSelect
                            isMulti
                            hideSelectedOptions={false}
                            id="keySkill"
                            name="keySkill"
                            value={formik.values.keySkill}
                            onBlur={formik.handleBlur}
                            onChange={(e) => {
                              formik.setFieldValue('keySkill', e);
                              handleKeySkillSuggestion(e);
                            }}
                            options={keySkillSuggestion}
                            placeholder={LABELS.INPUT.KEY_SKILLS}
                            className={classNames({
                              'p-invalid': isFormFieldValid(formik, 'keySkill')
                            })}
                            classNamePrefix="custom-create-select"
                            inputValue={keySkillInput}
                            onKeyDown={(e) => {
                              if (e.key === 'Enter') {
                                e.preventDefault();
                                e.stopPropagation();
                                if (e.target.value) {
                                  const newOption = {
                                    label: e.target.value,
                                    value: e.target.value
                                  };
                                  formik.setFieldValue('keySkill', [
                                    ...formik.values.keySkill,
                                    newOption
                                  ]);
                                  handleKeySkillSuggestion([newOption]);
                                  setKeySkillInput('');
                                }
                              }
                            }}
                            onInputChange={(inputValue) => {
                              setKeySkillInput(inputValue);
                            }}
                          />
                        </div>
                        {getFormErrorMessage(formik, 'keySkill')}
                      </div>
                    </div>
                    <div className="form-col has-full-width">
                      <div className="form-group-outer">
                        <div className="custom-form-group autocomplete">
                          <Label htmlFor="softSkill" text={LABELS.INPUT.SOFT_SKILLS} isBold />
                          <CreatableSelect
                            isMulti
                            hideSelectedOptions={false}
                            id="softSkill"
                            name="softSkill"
                            value={formik.values.softSkill}
                            onBlur={formik.handleBlur}
                            onChange={(e) => {
                              formik.setFieldValue('softSkill', e);
                              handleSoftSkillSuggestion(e);
                            }}
                            options={softSkillSuggestion}
                            placeholder={LABELS.INPUT.SOFT_SKILLS}
                            className={classNames({
                              'p-invalid': isFormFieldValid(formik, 'softSkill')
                            })}
                            classNamePrefix="custom-create-select"
                            inputValue={softSkillInput}
                            onKeyDown={(e) => {
                              if (e.key === 'Enter') {
                                e.preventDefault();
                                e.stopPropagation();
                                if (e.target.value) {
                                  const newOption = {
                                    label: e.target.value,
                                    value: e.target.value
                                  };
                                  formik.setFieldValue('softSkill', [
                                    ...formik.values.softSkill,
                                    newOption
                                  ]);
                                  handleSoftSkillSuggestion([newOption]);
                                  setSoftSkillInput('');
                                }
                              }
                            }}
                            onInputChange={(inputValue) => {
                              setSoftSkillInput(inputValue);
                            }}
                          />
                        </div>
                        {getFormErrorMessage(formik, 'softSkill')}
                      </div>
                    </div>
                    <div className="form-col has-full-width">
                      <div className="form-group-outer">
                        <div className="custom-form-group">
                          <Label htmlFor="address" text={LABELS.INPUT.ADDRESS} isBold />
                          <InputTextarea
                            placeholder={LABELS.INPUT.ADDRESS}
                            labelText={LABELS.INPUT.ADDRESS}
                            value={formik.values.address}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            errorMessage={formik.touched['address'] ? formik.errors['address'] : ''}
                            className={classNames({
                              'p-invalid': isFormFieldValid(formik, 'address')
                            })}
                            rows={5}
                            cols={30}
                            id="address"
                            name="address"
                          />
                        </div>
                        {getFormErrorMessage(formik, 'address')}
                      </div>
                    </div>
                    <div className="form-col form-col-3">
                      <div className="form-group-outer">
                        <div className="custom-form-group margin-bottom">
                          <Label htmlFor="gender" text={LABELS.INPUT.GENDER} isMandatory isBold />
                          <div className="radio-list-wrapper">
                            <div className="form-group-outer-radiobutton">
                              <RadioButton
                                inputId="male"
                                value={1}
                                name="gender"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                checked={formik.values.gender === 1}
                              />
                              <Label htmlFor="male" text={LABELS.INPUT.MALE} isBold />
                            </div>
                            <div className="form-group-outer-radiobutton">
                              <RadioButton
                                inputId="female"
                                value={2}
                                name="gender"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                checked={formik.values.gender === 2}
                              />
                              <Label htmlFor="female" text={LABELS.INPUT.FEMALE} isBold />
                            </div>
                          </div>
                        </div>
                        {getFormErrorMessage(formik, 'gender')}
                      </div>
                    </div>
                    <div className="form-col form-col-3">
                      <div className="form-group-outer">
                        <div className="custom-form-group margin-bottom">
                          <Label
                            htmlFor="maritalStatus"
                            text={LABELS.INPUT.MARITAL_STATUS}
                            isMandatory
                            isBold
                          />
                          <div className="radio-list-wrapper">
                            <div className="form-group-outer-radiobutton">
                              <RadioButton
                                inputId="single"
                                value={1}
                                name="maritalStatus"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                checked={formik.values.maritalStatus === 1}
                              />
                              <Label htmlFor="single" text={LABELS.INPUT.SINGLE} isBold />
                            </div>
                            <div className="form-group-outer-radiobutton">
                              <RadioButton
                                inputId="married"
                                value={2}
                                name="maritalStatus"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                checked={formik.values.maritalStatus === 2}
                              />
                              <Label htmlFor="married" text={LABELS.INPUT.MARRIED} isBold />
                            </div>
                          </div>
                        </div>
                        {getFormErrorMessage(formik, 'maritalStatus')}
                      </div>
                    </div>
                  </div>
                  <div className="form-btn-wrapper">
                    <Button type="submit" variant="contained" className="ims-blue-btn">
                      {LABELS.BUTTON.SAVE}
                    </Button>
                    <Button
                      variant="contained"
                      onClick={() => navigate(ROUTES.DASHBOARD)}
                      color="error"
                      className="gray-btn border-btn">
                      {LABELS.BUTTON.CANCEL}
                    </Button>
                  </div>
                </div>
              </div>
            </form>
          </TabPanel>
          <TabPanel header="Resume Management">
            {tabIndex === Tab_INDEX['Resume Management'] && <ResumeDetails />}
          </TabPanel>
          {/* <TabPanel header="Skills">
              <div className="form-row-wrapper">
                <div className="form-col full-width">
                  <div className="form-group-outer">
                    <div className="custom-form-group">
                      <Label htmlFor="keySkills" text={LABELS.INPUT.KEY_SKILLS} isBold />
                      <CreatableSelect
                        isMulti
                        hideSelectedOptions={false}
                        id="keySkills"
                        name="keySkills"
                        value={formik.values.keySkills}
                        onBlur={formik.handleBlur}
                        onChange={(e) => {
                          formik.setFieldValue('keySkills', e);
                          handleKeySkillsSuggestion(e);
                        }}
                        options={keySkillsSuggestion}
                        placeholder={LABELS.INPUT.KEY_SKILLS}
                        className={classNames({
                          'p-invalid': isFormFieldValid(formik,'keySkills')
                        })}
                        inputValue={keySkillsInput}
                        onKeyDown={(e) => {
                          if (e.key === 'Enter') {
                            e.preventDefault();
                            e.stopPropagation();
                            if (e.target.value) {
                              const newOption = { label: e.target.value, value: e.target.value };
                              formik.setFieldValue('keySkills', [
                                ...formik.values.keySkills,
                                newOption
                              ]);
                              handleKeySkillsSuggestion([newOption]);
                              setKeySkillsInput('');
                            }
                          }
                        }}
                        onInputChange={(inputValue) => {
                          setKeySkillsInput(inputValue);
                        }}
                      />
                    </div>
                    {getFormErrorMessage(formik, 'keySkills')}
                  </div>
                </div>
              </div>

              <div className="form-row-wrapper">
                <div className="form-col full-width">
                  <div className="form-group-outer">
                    <div className="custom-form-group">
                      <Label htmlFor="softSkills" text={LABELS.INPUT.SOFT_SKILLS} isBold />
                      <CreatableSelect
                        isMulti
                        hideSelectedOptions={false}
                        id="softSkills"
                        name="softSkills"
                        value={formik.values.softSkills}
                        onBlur={formik.handleBlur}
                        onChange={(e) => {
                          formik.setFieldValue('softSkills', e);
                          handleSoftSkillsSuggestion(e);
                        }}
                        options={softSkillsSuggestion}
                        placeholder={LABELS.INPUT.SOFT_SKILLS}
                        className={classNames({
                          'p-invalid': isFormFieldValid(formik,'softSkills')
                        })}
                        inputValue={softSkillsInput}
                        onKeyDown={(e) => {
                          if (e.key === 'Enter') {
                            e.preventDefault();
                            e.stopPropagation();
                            if (e.target.value) {
                              const newOption = { label: e.target.value, value: e.target.value };
                              formik.setFieldValue('softSkills', [
                                ...formik.values.softSkills,
                                newOption
                              ]);
                              handleSoftSkillsSuggestion([newOption]);
                              setSoftSkillsInput('');
                            }
                          }
                        }}
                        onInputChange={(inputValue) => {
                          setSoftSkillsInput(inputValue);
                        }}
                      />
                    </div>
                    {getFormErrorMessage(formik, 'softSkills')}
                  </div>
                </div>
              </div>
            </TabPanel> */}
          <TabPanel header="Project Details">
            {tabIndex === Tab_INDEX['Project Details'] && <ProjectDetails />}
          </TabPanel>
          <TabPanel header="Education">
            {tabIndex === Tab_INDEX.Education && <EducationDetails />}
          </TabPanel>
          <TabPanel header="Employment">
            {tabIndex === Tab_INDEX.Emplopment && <EmploymentDetails />}
          </TabPanel>
        </TabView>
      </div>

      <ProfileUpdatePassword
        show={showChangePasswordPopup}
        onHide={(value) => setShowChangePasswordPopup(value)}
      />
    </>
  );
}

export default Profile;
