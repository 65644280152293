import React, { useEffect, useRef, useState } from 'react';

// Constants
import { resumeValidationSchema } from '../constants/schemas';
import {
  getFormErrorMessage,
  isFormFieldValid,
  openResumeLink,
  ResumeButton
} from '../utils/general';
import { LABELS } from '../constants/general';

// Components
import Label from './Label';

// Utility Packages
import { classNames } from 'primereact/utils';
import { Sidebar } from 'primereact/sidebar';
import { Button } from 'primereact/button';
import { useSelector } from 'react-redux';
import { useFormik } from 'formik';
import { InputText } from 'primereact/inputtext';

// Utility Functions
import { updateResumeDetails } from '../middleware/resumeDetails';

import uploadIcon from '../assets/images/grid-upload-icon.svg';

const AddEditResume = (props) => {
  const { onHide, show, selectedResume } = props;

  // React useRef hooks.
  const fileRef = useRef(null);

  // Store
  const toastReff = useSelector((state) => state.notifications.toastReff);
  const { search, pageNumber, size } = useSelector((state) => state.resumeDetails);

  //State
  const [fileName, setFileName] = useState('');
  const [isFile, setIsFile] = useState(false);

  useEffect(() => {
    if (selectedResume?.FilePath) {
      setIsFile(true);
    }
  }, [selectedResume]);

  const formik = useFormik({
    initialValues: {
      resumeName: selectedResume?.ResumeName || '',
      file: selectedResume?.FilePath || ''
    },
    validationSchema: resumeValidationSchema,
    onSubmit: (values) => {
      const payload = new FormData();
      payload.append('resumeName', values.resumeName);
      payload.append('resume', values.file);
      updateResumeDetails(selectedResume, payload, search, pageNumber, size, onHide, toastReff);
    }
  });

  return (
    <>
      <Sidebar visible={show} onHide={onHide} position="right" className="sidebar-drawer">
        <div className="form-box-wrapper change-password-form">
          <div className="title-wrapper">
            <p className="card-title">Resume</p>
            <button className="p-sidebar-close" onClick={onHide}>
              <span className="p-sidebar-close-icon pi pi-times" />
            </button>
          </div>
          <form onSubmit={formik.handleSubmit} className="p-fluid">
            <div className="form-row-wrapper form-row-wrap">
              <div className="form-col full-width">
                <div className="form-group-outer">
                  <div className="custom-form-group">
                    <Label
                      htmlFor="resumeName"
                      text={LABELS.INPUT.RESUME_NAME}
                      isBold
                      isMandatory
                    />
                    <InputText
                      id="resumeName"
                      name="resumeName"
                      placeholder={LABELS.INPUT.RESUME_NAME}
                      value={formik.values.resumeName}
                      onChange={formik.handleChange}
                      className={classNames({
                        'p-invalid': isFormFieldValid(formik, 'resumeName')
                      })}
                    />
                  </div>
                  {getFormErrorMessage(formik, 'resumeName')}
                </div>
              </div>
              <div className="form-col full-width">
                <div className="form-group-outer upload-document-wrapper">
                  <div className="custom-form-group">
                    <Label htmlFor="file" text="Resume" isBold isMandatory />
                    <input
                      hidden
                      ref={fileRef}
                      type="file"
                      id="file"
                      name="file"
                      onChange={(e) => {
                        formik.setFieldValue('file', e.target.files[0]);
                        setFileName(e.target.files[0].name);
                        setIsFile('');
                      }}
                      accept=".doc, .docx, .pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                      className={classNames({
                        'p-invalid': isFormFieldValid('file')
                      })}
                    />
                  </div>
                  <div className="flex flex-wrap align-items-center">
                    <Button
                      text
                      type="button"
                      className="auto-width gray-btn upload-btn"
                      onClick={() => {
                        fileRef.current.click();
                      }}>
                      <div>
                        {/* <i className="pi pi-fw pi-upload" /> */}
                        <em>
                          <img src={uploadIcon} alt="upload icon" />
                        </em>
                        <span>
                          {!selectedResume?.ID
                            ? LABELS.INPUT.RESUME_UPLOAD
                            : LABELS.INPUT.RESUME_UPDATE}
                        </span>
                      </div>
                    </Button>
                    {selectedResume?.ID ? (
                      isFile ? (
                        <ResumeButton
                          onClick={() => openResumeLink(`${selectedResume?.FilePath}`)}
                        />
                      ) : (
                        <p>{fileName}</p>
                      )
                    ) : (
                      <p>{fileName}</p>
                    )}
                  </div>
                  {getFormErrorMessage(formik, 'file')}
                </div>
              </div>
            </div>
            <div className="form-btn-wrapper">
              <Button
                type="button"
                variant="contained"
                className="ims-blue-btn"
                onClick={formik.handleSubmit}>
                {LABELS.BUTTON.SAVE}
              </Button>
              <Button
                variant="contained"
                onClick={onHide}
                color="error"
                className="gray-btn border-btn">
                {LABELS.BUTTON.CANCEL}
              </Button>
            </div>
          </form>
        </div>
      </Sidebar>
    </>
  );
};

export default AddEditResume;
