// Constants
import { USER_API } from '../constants/configs';
import { API_REQUEST } from '../constants/general';
import { Actions } from '../redux/actions';

// Utility Functions
import API from '../utils/api';
import { dispatch } from '../utils/store';
import { handleError, handleSuccess } from '../utils/toast';

export const getProjectDetailsData = (search, pageNumber, size, toastReff) => {
  dispatch(Actions.User.SetLoading, true);
  const params = {
    ...(!!search ? { search } : {}),
    // ...(!!sortBy ? { sortBy } : {}),
    // ...(!!orderBy ? { orderBy } : {}),
    ...(!!pageNumber ? { page: pageNumber, limit: size } : {})
  };

  API(API_REQUEST.get, `${USER_API}/projects`, { params })
    .then((res) => {
      handleSuccess(toastReff, res);
      dispatch(Actions.User.SetLoading, false);
      dispatch(Actions.Projects.SetProjects, res?.data?.data?.data?.projects || []);
      dispatch(Actions.Projects.SetTotalRecords, res?.data?.data?.data?.count || 0);
    })
    .catch((error) => {
      dispatch(Actions.User.SetLoading, false);
      handleError(toastReff, error);
      console.log(error);
    });
};

export const deleteProject = (id, search, pageNumber, size, toastReff , setDeleteProjectModal) => {
  dispatch(Actions.User.SetLoading, true);
  API(API_REQUEST.delete, `${USER_API}/remove-project?projectId=${id}`)
    .then((res) => {
      handleSuccess(toastReff, res);
      dispatch(Actions.User.SetLoading, false);
      setDeleteProjectModal(false);
      getProjectDetailsData(search, pageNumber, size, toastReff);
    })
    .catch((error) => {
      dispatch(Actions.User.SetLoading, false);
      handleError(toastReff, error);
      console.log(error);
    });
};

export const updateProjectDetails = (
  selectedProject,
  data,
  onHide,
  search,
  pageNumber,
  size,
  toastReff
) => {
  dispatch(Actions.User.SetLoading, true);
  API(
    selectedProject?.ID ? API_REQUEST.put : API_REQUEST.post,
    selectedProject?.ID
      ? `${USER_API}/update-project?projectId=${selectedProject?.ID}`
      : `${USER_API}/add-project`,
    {
      ...data,
      technology: data.technology?.length
        ? data.technology.map((tech) => tech.value).toString()
        : ''
    }
  )
    .then((res) => {
      handleSuccess(toastReff, res);
      getProjectDetailsData(search, pageNumber, size, toastReff);
      onHide();
    })
    .catch((error) => {
      handleError(toastReff, error);
      dispatch(Actions.User.SetLoading, false);
      console.log(error);
    });
};
