import * as Yup from 'yup';

// Login
export const loginValidationSchema = Yup.object().shape({
  email: Yup.string()
    .trim()
    .nullable()
    .email('This email is not a valid format.')
    .required('Email is a required field.'),
  password: Yup.string().trim().required('Password is a required field.')
});

// Register
export const registerValidationSchema = Yup.object().shape({
  firstName: Yup.string()
    .matches(/^[a-zA-Z_ ]+$/, 'Firstname can consist of only alphanumeric characters.')
    .required('Firstname is a required field.')
    .min(3, 'Firstname should have minimum 3 characters.')
    .max(100, 'Firstname can have maximum 100 characters.'),
  lastName: Yup.string()
    .matches(/^[a-zA-Z_ ]+$/, 'Lastname can consist of only alphanumeric characters.')
    .required('Lastname is a required field.')
    .min(3, 'Lastname should have minimum 3 characters.')
    .max(100, 'Lastname can have maximum 100 characters.'),
  email: Yup.string()
    .trim()
    .email('This email is not a valid format.')
    .required('Email is a required field.'),
  password: Yup.string()
    .trim()
    .required('Password is a required field.')
    .min(6, 'Password is too short - should be 6 chars minimum.')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{6,})/,
      'Password has contain minimum 6 characters (includes 1 Upper, 1 Special, 1 Number).'
    ),
  confirmPassword: Yup.string()
    .trim()
    .required('Confirm password is a required field.')
    .oneOf([Yup.ref('password'), null], 'Password does not match.'),
  mobile: Yup.string()
    .matches(/^[0-9]+$/, 'Mobile must be numeric value.')
    .length(10, 'Mobile should have 10 digits.')
    .required('Mobile is a required field.')
});

// Forgot Password
export const forgotPasswordValidationSchema = Yup.object().shape({
  email: Yup.string()
    .trim()
    .nullable()
    .email('This email is not a valid format.')
    .required('Email is a required field.')
});

// Reset Password
export const resetPasswordValidationSchema = Yup.object({
  password: Yup.string()
    .trim()
    .required('Password is a required field.')
    .min(6, 'Password is too short - should be 6 chars minimum.')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{6,})/,
      'Password has contain minimum 6 characters (includes 1 Upper, 1 Special, 1 Number).'
    ),
  confirmPassword: Yup.string()
    .required('Confirm Password is required.')
    .oneOf([Yup.ref('password'), null], 'Password does not match.')
});

// Profile
export const profileValidationSchema = Yup.object().shape({
  firstName: Yup.string()
    .matches(/^[a-zA-Z_ ]+$/, 'Firstname can consist of only alphanumeric characters.')
    .required('Firstname is a required field.')
    .min(3, 'Firstname should have minimum 3 characters.')
    .max(100, 'Firstname can have maximum 100 characters.'),
  lastName: Yup.string()
    .matches(/^[a-zA-Z_ ]+$/, 'Lastname can consist of only alphanumeric characters.')
    .required('Lastname is a required field.')
    .min(3, 'Lastname should have minimum 3 characters.')
    .max(100, 'Lastname can have maximum 100 characters.'),
  email: Yup.string()
    .trim()
    .email('This email is not a valid format.')
    .required('Email is a required field.'),
  phone: Yup.string()
    .matches(/^[0-9]+$/, 'Phone must be numeric value.')
    .length(10, 'Phone should have 10 digits.')
    .required('Phone is a required field.'),
  secondPhone: Yup.string()
    .matches(/^[0-9]+$/, 'Second Mobile must be numeric value.')
    .length(10, 'Second Mobile should have 10 digits.')
    .nullable(),
  currentLocation: Yup.string('Current location must be of type string.').required(
    'Current location is required.'
  ),
  location: Yup.string('Current Location must be of type string.')
    .trim()
    .when('currentLocation', {
      is: (value) => value === 'other',
      then: (schema) => schema.required('Current Location is required.')
    }),
  gender: Yup.number().required('Gender is required.'),
  maritalStatus: Yup.number().required('Marital Status is required.'),
  profileImage: Yup.mixed()
    .test('format', 'Only the following formats are accepted: .jpeg, .jpg, .png', (value) => {
      if (!value) return true;
      if (typeof value === 'object') {
        return ['image/jpeg', 'image/jpg', 'image/png'].includes(value.type);
      }
      return true;
    })
    .test('fileSize', 'File size should be less than 1MB', (value) => {
      if (!value) return true;
      if (typeof value === 'object') {
        return value.size <= 1024 * 1024;
      }
      return true;
    }),
  dateOfBirth: Yup.date().required('Date of birth is required.')
});

// Profile
export const changePasswordValidationSchema = Yup.object({
  oldPassword: Yup.string().required('Old Password is required.'),
  password: Yup.string()
    .required('Password is required')
    .min(8, 'Password must be at least 8 characters')
    .matches(/^(?=.*[a-z]).*$/, 'Password must contain at least one lowercase letter.')
    .matches(/^(?=.*[A-Z]).*$/, 'Password must contain at least one uppercase letter.')
    .matches(/^(?=.*\d).*$/, 'Password must contain at least one digit.')
    .matches(
      /^(?=.*[!@#$%^&*()\-_=+{};:,<.>/?]).*$/,
      'Password must contain at least one special character.'
    )
    .matches(/^\S*$/, 'Password cannot contain spaces.'),
  confirmPassword: Yup.string()
    .required('Confirm Password is required.')
    .oneOf([Yup.ref('password'), null], 'Passwords must match')
});

// Education
export const educationValidationSchema = Yup.object({
  details: Yup.string()
    .required('Education details are required.')
    .max(255, 'Education details can have maximum 255 characters.'),
  course: Yup.string()
    .required('Course is a required field.')
    .max(255, 'Course can have maximum 100 characters.'),
  university: Yup.string()
    .required('University is a required field.')
    .max(255, 'University can have maximum 100 characters.'),
  passingYear: Yup.date()
    .typeError('Passing year must be a valid date.')
    .required('Passing year is required.'),
  grade: Yup.object({
    name: Yup.string(),
    code: Yup.string()
  })
    .required('Grade is a required field.')
    .test('not-empty', 'Grade is a required field.', (value) => {
      return value && Object.keys(value).length > 0;
    }),
  remarks: Yup.string()
});

// Resume
export const resumeValidationSchema = Yup.object({
  resumeName: Yup.string()
    .required('Resume Name are required.')
    .max(255, 'Resume Name can have maximum 255 characters.'),
  file: Yup.mixed()
    .required('File is required.')
    .test('is-valid-file', 'File must be either an object or a valid HTTPS URL.', (value) => {
      if (!value) return false; // Field is required, so it cannot be null or undefined
      if (typeof value === 'object') return true; // Allow objects (e.g., uploaded files)
      if (typeof value === 'string' && value.startsWith('http')) return true; // Allow valid HTTPS URLs
      return false; // Invalid file
    })
});

// Project
export const projectValidationSchema = Yup.object({
  projectTitle: Yup.string()
    .required('Project Title are required.')
    .max(255, 'Project Title can have maximum 255 characters.'),
  technology: Yup.array()
    .required('Technology is a required field.')
    .min(1, 'Technology is a required field.'),
  duration: Yup.number()
    .required('Duration is a required field.')
    .max(100, 'Duration cannot exceed 100.'),
  projectDetails: Yup.string().required('Project Details is a required field.')
});

// Employment
export const employmentValidationSchema = Yup.object({
  jobTitle: Yup.string()
    .required('Job Title is required.')
    .max(255, 'Job Title can have a maximum of 255 characters.'),
  currentEmployment: Yup.string().required('Current Employment status is required.'),
  employmentType: Yup.string().required('Employment Type is required.'),
  totalExperience: Yup.number()
    .required('Total Experience is required.')
    .max(100, 'Total Experience cannot exceed 100 years.'),
  companyName: Yup.string()
    .required('Company Name is required.')
    .max(255, 'Company Name can have a maximum of 255 characters.'),
  jobLocation: Yup.string()
    .required('Job Location is required.')
    .max(255, 'Job Location can have a maximum of 255 characters.'),
  startDate: Yup.date()
    .required('Start Date is required.')
    .max(new Date(), 'Start Date cannot be in the future.'),
  endDate: Yup.date()
    .required('End Date is required.')
    .min(Yup.ref('startDate'), 'End Date cannot be before Start Date.'),
  joiningSalary: Yup.number()
    .required('Joining Salary is required.')
    .max(100, 'Joining Salary should not more than 100 LPA.'),
  lastSalary: Yup.number()
    .required('Last Salary is required.')
    .max(100, 'Last Salary should not more than 100 LPA.')
});

//Jobs
export const ApplyJobValidationSchema = Yup.object().shape({
  firstName: Yup.string().required('First Name is required'),
  lastName: Yup.string().required('Last Name is required'),
  email: Yup.string().email('Invalid email').required('Email is required'),
  mobile: Yup.string().required('Mobile number is required'),
  totalExperience: Yup.number()
    .required('Years of experience is required')
    .min(0, 'Total Experience cannot be less than 0 years.')
    .max(100, 'Total Experience cannot exceed 100 years.'),
  currentLocation: Yup.string().required('Current location is required'),
  highestQualification: Yup.string().required('Highest qualification is required'),
  currentCompany: Yup.string()
    .required('Current company is required')
    .max(255, 'Current company Name can have a maximum of 255 characters.'),
  currentCTC: Yup.string()
    .required('Current CTC is required')
    .min(0, 'Current CTC should not be less than 0 LPA.')
    .max(100, 'Current CTC should not more than 100 LPA.'),
  expectedCTC: Yup.string()
    .required('Expected CTC is required')
    .min(0, 'Expected CTC should not be less than 0 LPA.')
    .max(100, 'Expected CTC should not more than 100 LPA.'),
  noticePeriod: Yup.string().required('Notice period is required'),
  resumeFile: Yup.string().required('Resume is required')
});
