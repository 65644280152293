import {
  INITIAL_ORDER,
  INITIAL_PAGE_NUMBER,
  INITIAL_SIZE,
  INITIAL_SORT
} from '../../constants/general';

export default function applyJobs(
  initialState = {
    applyJobs: [],
    pageNumber: INITIAL_PAGE_NUMBER,
    search: '',
    applyJobPageData: {},
    size: INITIAL_SIZE,
    totalRecords: 0,
    sortBy: INITIAL_SORT.APPLY_JOB,
    orderBy: INITIAL_ORDER.APPLY_JOB
  },
  action
) {
  switch (action.type) {
    case 'SET_APPLY_JOBS':
      return {
        ...initialState,
        applyJobs: action.payload
      };

    case 'SET_APPLY_JOBS_SEARCH':
      return {
        ...initialState,
        search: action.payload
      };

    case 'SET_APPLY_JOBS_SINGLE_JOB_DATA':
      return {
        ...initialState,
        applyJobPageData: action.payload
      };

    case 'SET_APPLY_JOBS_PAGE_NUMBER':
      return {
        ...initialState,
        pageNumber: action.payload
      };

    case 'SET_APPLY_JOBS_PAGE_SIZE':
      return {
        ...initialState,
        size: action.payload
      };
    case 'SET_APPLY_JOBS_SORT_BY':
      return {
        ...initialState,
        sortBy: action.payload
      };

    case 'SET_APPLY_JOBS_ORDER_BY':
      return {
        ...initialState,
        orderBy: action.payload
      };

    case 'SET_APPLY_JOBS_TOTAL_RECORDS':
      return {
        ...initialState,
        totalRecords: action.payload
      };

    case 'APPLY_JOBS_RESET':
      return {
        ...initialState,
        applyJobs: [],
        pageNumber: INITIAL_PAGE_NUMBER,
        search: '',
        applyJobPageData: {},
        size: INITIAL_SIZE,
        totalRecords: 0,
        sortBy: INITIAL_SORT.APPLY_JOB,
        orderBy: INITIAL_ORDER.APPLY_JOB
      };

    default:
      return initialState;
  }
}
