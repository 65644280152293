import React, { useState } from 'react';

// Constants
import { FORGOT_PASSWORD_API } from '../../constants/configs';
import { API_REQUEST, LABELS, ROUTES } from '../../constants/general';
import { forgotPasswordValidationSchema } from '../../constants/schemas';
import { getFormErrorMessage, isFormFieldValid, trimObjectValues } from '../../utils/general';

// Components
import Label from '../../components/Label';
import Asteric from '../../components/Asteric';

// Images
import logoImg from '../../assets/images/site-logo-black.svg';

// Utility Functions
import API from '../../utils/api';
import { handleError, handleSuccess } from '../../utils/toast';

// Utility Packages
import { Link, useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { classNames } from 'primereact/utils';
import { useSelector } from 'react-redux';
import { dispatch } from '../../utils/store';
import { Actions } from '../../redux/actions';

const ForgotPassword = () => {
  const navigate = useNavigate();

  // State
  const [onFocus, setOnFocus] = useState(false);

  // Store
  const toastReff = useSelector((state) => state.notifications.toastReff);

  const formik = useFormik({
    initialValues: {
      email: ''
    },
    validationSchema: forgotPasswordValidationSchema,
    onSubmit: (values, { resetForm }) => {
      trimObjectValues(values);
      forgotPassword(
        {
          email: values.email
        },
        resetForm
      );
    }
  });

  const forgotPassword = (email, resetForm) => {
    dispatch(Actions.User.SetLoading, true);
    API(API_REQUEST.post, FORGOT_PASSWORD_API, email)
      .then((res) => {
        handleSuccess(toastReff, res);
        resetForm({ values: '' });
        dispatch(Actions.User.SetLoading, false);
        navigate(ROUTES.LOGIN);
      })
      .catch((error) => {
        handleError(toastReff, error);
        dispatch(Actions.User.SetLoading, false);
        console.log(error);
      });
  };

  const handleOnFocus = () => {
    setOnFocus(true);
  };

  const handleOnBlur = () => {
    setOnFocus(false);
  };

  return (
    <div className="authentication-wrapper forgot-pwd">
      <div className="authentication-inner">
        <div className="login-container">
          <div className="site-logo">
            <a href={ROUTES.HOME} title="Interview ManagementSystem">
              <img src={logoImg} alt="" onClick={() => navigate(ROUTES.HOME)} />
            </a>
          </div>
          <h2>{LABELS.FORGOT_PASSWORD}</h2>
          <p>{LABELS.FORGOT_PASSWORD_LABEL}</p>
          <form onSubmit={formik.handleSubmit} className="p-fluid">
            <div className="field">
              <Label htmlFor="email" text={LABELS.INPUT.EMAIL} isBold onFocus={onFocus} />
              <Asteric />
              <span className="p-input-icon-right">
                <i className="pi pi-user" />
                <InputText
                  id="email"
                  name="email"
                  placeholder={LABELS.INPUT.EMAIL}
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  onFocus={handleOnFocus}
                  onBlur={handleOnBlur}
                  className={classNames({
                    'p-invalid': isFormFieldValid(formik, 'email')
                  })}
                />
              </span>
              {getFormErrorMessage(formik, 'email')}
            </div>
            <Button className="w-full" onClick={formik.handleSubmit}>
              {LABELS.BUTTON.SEND}
            </Button>
            <div className="link-wrapper back-login">
              <Link to={ROUTES.LOGIN}>
                <span className="pi pi-angle-left"></span>
                {LABELS.BACK_TO_LOGIN}
              </Link>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
