import { INITIAL_PAGE_NUMBER, INITIAL_SIZE } from '../../../constants/general';

export default function employmentDetails(
  initialState = {
    employments: [],
    pageNumber: INITIAL_PAGE_NUMBER,
    search: '',
    size: INITIAL_SIZE,
    totalRecords: 0,
    sortBy: 'StartDateOfInterviewRound',
    orderBy: 'DESC'
  },
  action
) {
  switch (action.type) {
    case 'SET_EMPLOYMENTS':
      return {
        ...initialState,
        employments: action.payload
      };

    case 'SET_EMPLOYMENTS_SEARCH':
      return {
        ...initialState,
        search: action.payload
      };

    case 'SET_EMPLOYMENTS_PAGE_NUMBER':
      return {
        ...initialState,
        pageNumber: action.payload
      };

    case 'SET_EMPLOYMENTS_PAGE_SIZE':
      return {
        ...initialState,
        size: action.payload
      };

    case 'SET_EMPLOYMENTS_SORT_BY':
      return {
        ...initialState,
        sortBy: action.payload
      };

    case 'SET_EMPLOYMENTS_ORDER_BY':
      return {
        ...initialState,
        orderBy: action.payload
      };

    case 'SET_EMPLOYMENTS_TOTAL_RECORDS':
      return {
        ...initialState,
        totalRecords: action.payload
      };

    case 'EMPLOYMENTS_RESET':
      return {
        ...initialState,
        employments: [],
        pageNumber: INITIAL_PAGE_NUMBER,
        search: '',
        size: INITIAL_SIZE,
        totalRecords: 0,
        sortBy: 'StartDateOfInterviewRound',
        orderBy: 'DESC'
      };

    default:
      return initialState;
  }
}
