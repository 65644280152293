// components
import BannerInnerPage from '../../components/BannerInnerPage';
import Header from '../../components/Header';
import Footer from '../../components/Footer';

import { useEffect } from 'react';

// Utility Functions
import { getJobDetails } from '../../middleware/jobDetails';

// Utility Packages
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { ROUTES } from '../../constants/general';

function JobDetails() {
  const { id } = useParams();

  // Store
  const { jobPageData } = useSelector((state) => state.jobs);
  const toastRef = useSelector((state) => state.notifications.toastReff);

  useEffect(() => {
    if (id) {
      getJobDetails(id, toastRef);
    }
  }, []);

  return (
    <>
      <div className="main-site-wrapper">
        <Header />
        <main className="main-wrapper job-detail-page">
          <BannerInnerPage title={jobPageData.JobTitle} />
          <div className="career-detail-page-content">
            <div className="container">
              <div className="career-detail-description">
                <p>{jobPageData.JobTitle}</p>
                <p>Experience: {jobPageData.Experience} Y</p>
                <p>Salary: {jobPageData.Salary} LPA</p>
              </div>
              <div
                className="career-content-block"
                dangerouslySetInnerHTML={{ __html: jobPageData.JobDescription }}
              />
              <p>
                To apply for this position mail your updated Resume on{' '}
                <a href="mailto:career@tatvasoft.com">career@tatvasoft.com</a>
              </p>
              <div className="btn-wrapper">
                <input type="hidden" />
                <a href={ROUTES.LOGIN} className="btn yellow-btn">
                  Apply now
                </a>
              </div>
            </div>
          </div>
        </main>
        <Footer />
      </div>
    </>
  );
}

export default JobDetails;
