import React from 'react'

// Images

import bannerMobileWebp from '../assets/images/privacy-terms-banner-mob.webp';
import bannerMobileJpg from '../assets/images/privacy-terms-banner-mob.jpg';
import bannerWebp from '../assets/images/privacy-terms-banner.webp';
import bannerJpg from '../assets/images/privacy-terms-banner.jpg';


const BannerInnerPage = ({title}) => {
  return (
    <section className='tatvasoft-inner-page-banner'>
         <picture className="tatvasoft-inner-banner">
          <source media="(min-width: 768px)" srcSet={`${bannerWebp}, ${bannerJpg}`} />
          <source srcSet={`${bannerMobileWebp}, ${bannerMobileJpg}`} />
          <img src={bannerMobileJpg} alt="Banner Preview" />
        </picture>
        <div className="banner-content-block">
            <div className="container">
                <div className="banner-content">
                    <h1>{title}</h1>
                </div>
            </div>
        </div>
    </section>
  )
}

export default BannerInnerPage