// Reducers
import auth from './reducers/auth';
import interviews from './reducers/interviews';
import notifications from './reducers/notifications';
import projectDetails from './reducers/profile/projectDetails';
import educationDetails from './reducers/profile/educationDetails';
import employmentDetails from './reducers/profile/employmentDetails';
import resumeDetails from './reducers/profile/resumeDetails';
import jobs from './reducers/jobs';

// Utility Packages
import { combineReducers } from 'redux';
import applyJobs from './reducers/applyJobs';

/* *********************** */
/* ***** Imports End ***** */
/* *********************** */

export default combineReducers({
  auth,
  notifications,
  interviews,
  projectDetails,
  educationDetails,
  employmentDetails,
  resumeDetails,
  jobs,
  applyJobs
});
