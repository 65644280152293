import { INITIAL_PAGE_NUMBER, INITIAL_SIZE } from '../../../constants/general';

export default function projectDetails(
  initialState = {
    projects: [],
    pageNumber: INITIAL_PAGE_NUMBER,
    search: '',
    size: INITIAL_SIZE,
    totalRecords: 0,
    sortBy: 'StartDateOfInterviewRound',
    orderBy: 'DESC'
  },
  action
) {
  switch (action.type) {
    case 'SET_PROJECTS':
      return {
        ...initialState,
        projects: action.payload
      };

    case 'SET_PROJECTS_SEARCH':
      return {
        ...initialState,
        search: action.payload
      };

    case 'SET_PROJECTS_PAGE_NUMBER':
      return {
        ...initialState,
        pageNumber: action.payload
      };

    case 'SET_PROJECTS_PAGE_SIZE':
      return {
        ...initialState,
        size: action.payload
      };

    case 'SET_PROJECTS_SORT_BY':
      return {
        ...initialState,
        sortBy: action.payload
      };

    case 'SET_PROJECTS_ORDER_BY':
      return {
        ...initialState,
        orderBy: action.payload
      };

    case 'SET_PROJECTS_TOTAL_RECORDS':
      return {
        ...initialState,
        totalRecords: action.payload
      };

    case 'PROJECTS_RESET':
      return {
        ...initialState,
        projects: [],
        pageNumber: INITIAL_PAGE_NUMBER,
        search: '',
        size: INITIAL_SIZE,
        totalRecords: 0,
        sortBy: 'StartDateOfInterviewRound',
        orderBy: 'DESC'
      };

    default:
      return initialState;
  }
}
