import React, { useState } from 'react';

// APIs
import { LOGIN_API } from '../../constants/configs';

// Constants
import { API_REQUEST, LABELS, ROUTES, ROLES } from '../../constants/general';

// Components
import Label from '../../components/Label';
import Asteric from '../../components/Asteric';
import CustomPassword from '../../components/CustomPassword';

// Images
import logoImg from '../../assets/images/site-logo-black.svg';
import siteLogoTatva from '../../assets/images/site-logo-tatva.svg';
import userIcon from '../../assets/images/auth-user-icon.svg';

// Utility Functions
import { loginValidationSchema } from '../../constants/schemas';
import { getFormErrorMessage, isFormFieldValid, trimObjectValues } from '../../utils/general';
import API from '../../utils/api';
import { handleError, handleSuccess } from '../../utils/toast';
import { setAccessToken, setLocalStorageUserData } from '../../utils/session';

// Utility Packages
import { useFormik } from 'formik';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Link, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { classNames } from 'primereact/utils';
import { dispatch } from '../../utils/store';
import { Actions } from '../../redux/actions';

export default function Login() {
  const navigate = useNavigate();

  // Store
  const toastReff = useSelector((state) => state.notifications.toastReff);

  // State
  const [onFocus, setOnFocus] = useState(false);

  // Handle form values
  const formik = useFormik({
    initialValues: {
      email: '',
      password: ''
    },
    validationSchema: loginValidationSchema,
    onSubmit: (values, { resetForm }) => {
      trimObjectValues(values);
      userLogin(
        {
          email: values.email,
          password: values.password
        },
        resetForm
      );
    }
  });

  const userLogin = (userData, resetForm) => {
    dispatch(Actions.User.SetLoading, true);
    API(API_REQUEST.post, LOGIN_API, userData)
      .then((res) => {
        dispatch(Actions.User.SetLoading, false);
        if (res?.data?.data?.data?.userData?.userTypeId === ROLES.CANDIDATE) {
          handleSuccess(toastReff, res);
          setLocalStorageUserData(res?.data?.data?.data?.userData);
          setAccessToken(res?.data?.data?.data?.token);
          resetForm({ values: '' });
          navigate(ROUTES.DASHBOARD);
        }
      })
      .catch((error) => {
        handleError(toastReff, error);
        dispatch(Actions.User.SetLoading, false);
        console.log(error);
      });
  };

  const handleOnFocus = () => {
    setOnFocus(true);
  };

  const handleOnBlur = () => {
    setOnFocus(false);
  };

  return (
    <div className="authentication-wrapper">
      <div className="authentication-inner">
        <div className="login-container">
          <div className="site-logo">
            <a href={ROUTES.HOME} title="Interview Management System">
              <img src={logoImg} alt="Site Logo" onClick={() => navigate(ROUTES.HOME)} />
            </a>
          </div>
          <h2>{LABELS.WELCOME}</h2>
          <form className="p-fluid">
            <div className="field">
              <Label htmlFor="email" text={LABELS.INPUT.EMAIL} isBold onFocus={onFocus} />
              <Asteric />
              <span className="p-input-icon-right">
                {/* <i className="pi pi-user" /> */}
                <em>
                  <img src={userIcon} alt="user icon" />
                </em>
                <InputText
                  id="email"
                  name="email"
                  placeholder={LABELS.INPUT.EMAIL}
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  onFocus={handleOnFocus}
                  onBlur={handleOnBlur}
                  className={classNames({
                    'p-invalid': isFormFieldValid(formik, 'email')
                  })}
                />
              </span>
              {getFormErrorMessage(formik, 'email')}
            </div>
            <div className="field pwd">
              <CustomPassword
                htmlFor="password"
                text={LABELS.INPUT.PASSWORD}
                ismandatory
                isbold
                id="password"
                name="password"
                placeholder="Password"
                value={formik.values.password}
                onChange={formik.handleChange}
                toggleMask
                className={classNames({ 'p-invalid': isFormFieldValid(formik, 'password') })}
                feedback={false}
              />
              {getFormErrorMessage(formik, 'password')}
            </div>
            <div className="link-wrapper space-between">
              <Link to={ROUTES.FORGOT_PASSWORD}>Forgot password?</Link>
            </div>
            <Button className="w-full" onClick={formik.handleSubmit}>
              {LABELS.BUTTON.SIGN_IN}
            </Button>
            <div className="link-wrapper center">
              <span>Don't have an account?</span>
              <Link to={ROUTES.REGISTER}>{LABELS.BUTTON.SIGN_UP}</Link>
            </div>
          </form>
          <div className="tatva-logo-wrap">
            <span>{LABELS.POWERED_BY}</span>
            <img src={siteLogoTatva} alt="Tatvasoft" />
          </div>
        </div>
      </div>
    </div>
  );
}
