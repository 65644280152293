import { INITIAL_PAGE_NUMBER, INITIAL_SIZE } from '../../../constants/general';

export default function resumeDetails(
  initialState = {
    resumes: [],
    pageNumber: INITIAL_PAGE_NUMBER,
    search: '',
    size: INITIAL_SIZE,
    totalRecords: 0,
    sortBy: 'StartDateOfInterviewRound',
    orderBy: 'DESC'
  },
  action
) {
  switch (action.type) {
    case 'SET_RESUMES':
      return {
        ...initialState,
        resumes: action.payload
      };

    case 'SET_RESUMES_SEARCH':
      return {
        ...initialState,
        search: action.payload
      };

    case 'SET_RESUMES_PAGE_NUMBER':
      return {
        ...initialState,
        pageNumber: action.payload
      };

    case 'SET_RESUMES_PAGE_SIZE':
      return {
        ...initialState,
        size: action.payload
      };

    case 'SET_RESUMES_SORT_BY':
      return {
        ...initialState,
        sortBy: action.payload
      };

    case 'SET_RESUMES_ORDER_BY':
      return {
        ...initialState,
        orderBy: action.payload
      };

    case 'SET_RESUMES_TOTAL_RECORDS':
      return {
        ...initialState,
        totalRecords: action.payload
      };

    case 'RESUMES_RESET':
      return {
        ...initialState,
        resumes: [],
        pageNumber: INITIAL_PAGE_NUMBER,
        search: '',
        size: INITIAL_SIZE,
        totalRecords: 0,
        sortBy: 'StartDateOfInterviewRound',
        orderBy: 'DESC'
      };

    default:
      return initialState;
  }
}
