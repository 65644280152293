// Constants
import { USER_API } from '../constants/configs';
import { API_REQUEST } from '../constants/general';
import { Actions } from '../redux/actions';

// Utility Functions
import API from '../utils/api';
import { dispatch } from '../utils/store';
import { handleError, handleSuccess } from '../utils/toast';

export const getEducationDetailsData = (search, pageNumber, size, toastReff) => {
  dispatch(Actions.User.SetLoading, true);
  const params = {
    ...(!!search ? { search } : {}),
    // ...(!!sortBy ? { sortBy } : {}),
    // ...(!!orderBy ? { orderBy } : {}),
    ...(!!pageNumber ? { page: pageNumber, limit: size } : {})
  };

  API(API_REQUEST.get, `${USER_API}/list-education`, { params })
    .then((res) => {
      handleSuccess(toastReff, res);
      dispatch(Actions.User.SetLoading, false);
      dispatch(Actions.Educations.SetEducations, res?.data?.data?.data?.education || []);
      dispatch(Actions.Educations.SetTotalRecords, res?.data?.data?.data?.count || 0);
    })
    .catch((error) => {
      dispatch(Actions.User.SetLoading, false);
      handleError(toastReff, error);
      console.log(error);
    });
};

export const deleteEducation = (id, search, pageNumber, size, toastReff , setDeleteEducationModal) => {
  dispatch(Actions.User.SetLoading, true);
  API(API_REQUEST.delete, `${USER_API}/remove-education?id=${id}`)
    .then((res) => {
      handleSuccess(toastReff, res);
      dispatch(Actions.User.SetLoading, false);
      setDeleteEducationModal(false);
      getEducationDetailsData(search, pageNumber, size, toastReff);
    })
    .catch((error) => {
      dispatch(Actions.User.SetLoading, false);
      handleError(toastReff, error);
      console.log(error);
    });
};

export const updateEducationDetails = (
  selectedEducation,
  data,
  search,
  pageNumber,
  size,
  onHide,
  toastReff
) => {
  dispatch(Actions.User.SetLoading, true);
  API(
    selectedEducation?.ID ? API_REQUEST.put : API_REQUEST.post,
    selectedEducation?.ID
      ? `${USER_API}/update-education?id=${selectedEducation?.ID}`
      : `${USER_API}/add-education`,
    { ...data }
  )
    .then((res) => {
      handleSuccess(toastReff, res);
      getEducationDetailsData(search, pageNumber, size, toastReff);
      onHide();
    })
    .catch((error) => {
      handleError(toastReff, error);
      dispatch(Actions.User.SetLoading, false);
      console.log(error);
    });
};
